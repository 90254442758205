import { Col, Row } from "antd";
import React from "react";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
export default function FormLayout(props: { children: React.ReactNode }) {
  return (
    <Row justify="center">
      <Col
        flex="auto"
        style={{
          maxWidth: "1280px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.children}
      </Col>
    </Row>
  );
}
