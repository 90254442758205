import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON49, normalText12 } from "../../utils";
export default function step6({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON49 {
	return {
		"6_คณะกรรมการประจำคณะปตรี": normalText12(
			values.input["คณะกรรมการประจำคณะปตรี"]
		),
		"6_นามคณะกรรมการประจำคณะปตรี": normalText12(
			userData.name + " " + userData.surname
		),
		"6_วันลงนามคณะกรรมการประจำคณะปตรี": dateFormat(new Date()),
		"6_ไม่อนุมัติเพราะ": normalText12(values.input["ไม่อนุมัติเพราะ"]),
		"6_ตำแหน่งคณะกรรมการประจำคณะปตรี": normalText12(
			values.input["ตำแหน่งคณะกรรมการประจำคณะปตรี"]
		),
		"6_ความเห็นกรรมการคณะ": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: ["อนุมัติ", "ไม่อนุมัติ"],
		},
	};
}
