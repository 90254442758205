import { Divider, Button, Input, Form, Col, Row, notification } from "antd";
import { updateUserInfo } from "../Providers/UserProvider";
import Loader from "../Components/common/Loader";
import FormLayout from "../Components/layout/FormLayout";

import { useInfoContext } from "../Providers/UserProvider";
import { updateUserProfile } from "../utils/api/user";
//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//

//-------------------------------------------------------------------------//
// TODO : Save user's profile with api
//-------------------------------------------------------------------------//

function Profile() {
  const infoContext = useInfoContext();
  const { userData, setUserData } = infoContext;

  const {
    name,
    surname,
    id,
    faculty,
    department,
    isStaff,
    address,
    phone,
    email,
  } = userData;
  const [form] = Form.useForm();

  const { TextArea } = Input;

  const layout = {
    labelCol: {
      xs: {
        span: 2,
      },
      sm: {
        span: 2,
      },
      md: {
        span: 2,
        offset: 4,
      },
      lg: {
        span: 2,
        offset: 4,
      },
    },
    wrapperCol: {
      span: 14,
    },
  };

  const buttonLayout = {
    wrapperCol: {
      xs: {
        span: 2,
      },
      sm: {
        span: 2,
      },
      md: {
        offset: 18,
        span: 2,
      },
      lg: {
        offset: 18,
        span: 2,
      },
    },
  };

  const onFinish = (e: {
    phone?: string;
    email?: string;
    department?: string;
  }) => {
    updateUserProfile(e)
      .then((e: any) => {
        console.log(e);
        updateUserInfo(e, setUserData);
        notification["success"]({
          message: "สำเร็จ",
          description: "คุณได้ทำการแก้ไขข้อมูลส่วนตัวเรียบร้อยแล้ว",
        });
      })
      .catch((e) => {
        const msg = e.response.data.message.join("\n");
        console.log(msg);

        notification["error"]({
          message: "ไม่สำเร็จ",
          description: msg,
        });
      });
    // setUserData((prevState: UserInfo) => ({
    // 	...prevState,
    // 	address: e.address,
    // }));
  };

  const person: any = {
    nameAndSurname: name + " " + surname,
    id: id,
    faculty: faculty,
    phone,
    email,
    department: department,
    status: isStaff ? "เจ้าหน้าที่ทะเบียน" : "นิสิต",
  };
  const editAble = ["phone", "email", "department"];
  const labelTag: any = {
    nameAndSurname: "ชื่อ-สกุล",
    id: "รหัสนิสิต",
    faculty: "คณะ",
    phone: "เบอร์โทรศัพท์",
    email: "Email",
    department: "ภาควิชา",
    status: "สถานะ",
  };

  const keys = Object.keys(person);
  if (!userData.id) return <Loader />;
  return (
    <FormLayout>
      <Divider>
        <div
          style={{
            fontSize: "1.5em",
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          ประวัติส่วนตัว
        </div>
      </Divider>

      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        {...layout}
        labelAlign="left"
      >
        {keys.map((item, idx) => {
          return (
            <Row key={idx}>
              <Col flex="auto" md={24}>
                <Form.Item
                  key={idx}
                  initialValue={person[item]}
                  name={item}
                  label={
                    <label style={{ fontWeight: "bold" }}>
                      {labelTag[item]}
                    </label>
                  }
                >
                  <Input disabled={!editAble.some((e) => e === item)} />
                </Form.Item>
              </Col>
            </Row>
          );
        })}

        <Divider style={{ marginTop: "35px", marginBottom: "35px" }}>
          <div>สำหรับการจัดส่งเอกสาร</div>
        </Divider>

        <Form.Item name="address" initialValue={address} label="ที่อยู่">
          <TextArea defaultValue={address} showCount maxLength={300} />
        </Form.Item>

        <Form.Item {...buttonLayout} style={{ marginTop: "40px" }}>
          <Button block htmlType="submit" type="primary">
            Save
          </Button>
        </Form.Item>
      </Form>
    </FormLayout>
  );
}

export default Profile;
