import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON41, normalText12 } from "../../utils";

const getDate = (d: Date) => [
	d.getDate().toString(),
	(d.getMonth() + 1).toString(),
	(d.getFullYear() + 543).toString(),
];

export default function step4({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON41 {
	return {
		"4_ความเห็นนายทะเบียนคณะ": normalText12(
			values.input["ความเห็นนายทะเบียนคณะ"]
		),
		"4_นามนายทะเบียนคณะ": normalText12(
			userData.name + " " + userData.surname
		),
		"4_นายทะเบียนคณะลงนาม": normalText12(
			userData.name + " " + userData.surname
		),
		"4_วันลงนามนายทะเบียนคณะ": dateFormat(new Date()),
		"4_ทะเบียนคณะ_วันที่รับ": normalText12(getDate(new Date()).join("/")),
		"4_ทะเบียนคณะ_เวลา": normalText12(new Date().toLocaleTimeString("th")),
		"4_ทะเบียนคณะ_ผู้รับ": normalText12(
			userData.name + " " + userData.surname
		),
	};
}
