import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { DocumentJSONTypes, getDraftedData } from "../../Forms/utils";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//

const ReasonBox = (props: {
  label: string;
  step: number;
  data: DocumentJSONTypes;
  forceInitName?: string;
}) => {
  const textboxName = `${props.step}_${props.label}`;
  return (
    <Form.Item
      style={{ marginBottom: "30px" }}
      name={["input", props.label]}
      label={props.label}
      initialValue={getDraftedData(
        props.forceInitName || textboxName,
        props.data
      )}
    >
      <TextArea showCount maxLength={300} />
    </Form.Item>
  );
};

export default ReasonBox;
