import DeclineButton from "../../../Components/forms/DeclineButton";
import FilesButton from "../../../Components/forms/FilesButton";
import SendButton from "../../../Components/forms/SendButton";
import { DocumentJSONTypes } from "../../utils";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  data: DocumentJSONTypes;
}

export default function Step5(props: Props) {
  return (
    <>
      <FilesButton step={5} templateCode="32" />
      <SendButton />
      <DeclineButton step={5} templateCode="32" />
    </>
  );
}
