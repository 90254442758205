import FilesButton from "../../../Components/forms/FilesButton";
import ReasonBox from "../../../Components/forms/ReasonBox";
import SendButton from "../../../Components/forms/SendButton";

import { DocumentJSONTypes } from "../../utils";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  data: DocumentJSONTypes;
}

export default function Step6(props: Props) {
  return (
    <>
      <ReasonBox label="ความเห็นผู้อำนวยการ" step={6} data={props.data} />
      <FilesButton step={6} templateCode="32" />
      <SendButton label="ดำเนินการได้" />
    </>
  );
}
