import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, getUserInput, normalText12, studentID } from "../../utils";

export default function step1({
  userData,
  values,
}: {
  userData: UserInfo;
  values: any;
}) {
  const data: { [key: string]: any } = {};
  Object.keys(values?.input).forEach((e: string) => {
    data[`1_${e}`] = normalText12(values.input[e]);
  });
  console.log(data);

  return {
    ...data,
    "1_รหัสนิสิต": studentID(getUserInput(values, "รหัสนิสิต")),
    "1_วันลงนามนิสิต": dateFormat(new Date()),
  };
}
