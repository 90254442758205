import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON49, normalText12 } from "../../utils";
export default function step3({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON49 {
	return {
		"3_กรรมการหลักสูตรลงนาม": normalText12(
			userData.name + " " + userData.surname
		),
		"3_วันลงนามกรรมการหลักสูตร": dateFormat(new Date()),
		"3_นามกรรมการหลักสูตร": normalText12(
			userData.name + " " + userData.surname
		),
		"3_ความเห็นคณะกรรมการบริหารหลักสูตร": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: ["เห็นชอบ", "ไม่เห็นชอบ"],
		},
		"3_ตำแหน่งกรรมการหลักสูตร": normalText12(
			values.input["ตำแหน่งกรรมการหลักสูตร"]
		),
	};
}
