import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, normalText12, studentID, getUserInput } from "../../utils";

export default function step1({
  userData,
  values,
}: {
  userData: UserInfo;
  values: any;
}) {
  const data: { [key: string]: any } = {};

  Object.keys(values?.input).forEach((e: any) => {
    if (typeof e === "string") {
      data[`1_${e}`] = normalText12(values.input[e]);
    }
  });

  return {
    ...data,
    "1_ระบบการศึกษา": {
      isLocked: false,
      action: "Radio",
      data: values.input.ระบบการศึกษา,

      meta: ["ทวิภาค", "ทวิภาค-นานาชาติ", "ตรีภาค"],
    },
    "1_นิสิตระดับ": {
      isLocked: false,
      action: "Radio",
      data: values.input.นิสิตระดับ,

      meta: ["ปริญญาตรี", "บัณฑิตศึกษา"],
    },
    "1_วันลงนามนิสิต": dateFormat(new Date()),
    "1_รหัสนิสิต": studentID(getUserInput(values, "รหัสนิสิต")),
  };
}
