import { UserInfo } from "../../../Providers/UserProvider";
import step1 from "./step1";
import step2 from "./step2";
import step3 from "./step3";
import step4 from "./step4";
import step5 from "./step5";
import step6 from "./step6";
import step7 from "./step7";
import step8 from "./step8";
interface Props {
	index: number;
	userData: UserInfo;
	values: any;
}
const index = ({ index, userData, values }: Props) => {
	if (index === 1) return step1({ userData, values });
	if (index === 2) return step2({ userData, values });
	if (index === 3) return step3({ userData, values });
	if (index === 4) return step4({ userData, values });
	if (index === 5) return step5({ userData, values });
	if (index === 6) return step6({ userData, values });
	if (index === 7) return step7({ userData, values });
	if (index === 8) return step8({ userData, values });

	return { invalid: true };
};
export default index;
