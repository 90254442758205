import { Divider, Table, Row, Spin, Input, Col } from "antd";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import Loader from "../../Components/common/Loader";
import InfiniteScrollHook from "../../hooks/InfiniteScrollHook";
import { staffTableHeader } from "../../utils/tableHeader";
//-------------------------------------------------------------------------//
// summary :  component constance section
//-------------------------------------------------------------------------//
const { Search } = Input;

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
function StaffManage() {
  const history = useHistory();

  const [creator, setCreator] = useState("");

  const query: any = {
    status: "Pending",
  };
  if (creator !== "") {
    query.creatorId = creator;
  } else {
    query.isCreator = false;
    query.isHolder = true;
  }
  const { fetchMoreData, isLoading, items, hasMore } = InfiniteScrollHook({
    query,
  });
  const onSearch = (value: any) => {
    setCreator(value);
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Divider>
        <div
          style={{
            fontSize: "1.5em",
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          เอกสารในระบบ
        </div>
      </Divider>
      <Row justify="center" style={{ padding: "0px 0 25px 0" }}>
        <Col flex="auth" xs={24} sm={24} md={12} style={{ maxWidth: "1024px" }}>
          <Search
            defaultValue={creator}
            allowClear
            placeholder="กรอกรหัสนิสิตเพื่อค้นหา"
            onSearch={onSearch}
            enterButton
          />
        </Col>
      </Row>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <Row style={{ padding: "50px 0" }} justify="center">
            <Spin />
          </Row>
        }
        endMessage={
          <Row style={{ padding: "50px 0" }} justify="center">
            {`ดูเอกสารทั้งหมดครบแล้ว ${new Date().toString()}`}{" "}
          </Row>
        }
      >
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`/staff/document?id=${record.docID}`);
              }, // click row
              onDoubleClick: (event) => {}, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {}, // mouse leave row
            };
          }}
          style={{ width: "100%" }}
          pagination={false}
          dataSource={items}
          columns={staffTableHeader as any[]}
        />
      </InfiniteScroll>
    </div>
  );
}

export default StaffManage;
