export const PROD = process.env.REACT_APP_STAGE === "production";

export const REAL_API_HOST = "https://account.it.chula.ac.th";
export const FAKE_API_HOST = "http://localhost:3000";
export const API_HOST = PROD ? REAL_API_HOST : FAKE_API_HOST;
export const STAFF_LIST = [
  "6000000022",
  "6000000023",
  "6000000024",
  "6000000025",
  "6000000026",
  "6000000021",
];
