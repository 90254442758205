import React, { useState, createContext, useContext, useEffect } from "react";
import { STAFF_LIST } from "../utils/config";
import { getLoginTicket, logout } from "../utils/api/auth";
import { httpClient } from "../utils/api/http";
import { getUserInfo } from "../utils/api/user";
import Loader from "../Components/common/Loader";

export interface UserInfo {
  id: string;
  name: string;
  surname: string;
  thName: string;
  thSurname: string;
  email: string;
  phone: string;
  faculty: string;
  department: string;
  isStaff?: boolean;
  address?: string;
  authenticated?: Boolean;
}

export interface InfoContruct {
  userData: UserInfo;
  setUserData: Function;
  emptyUser: UserInfo;
}

export const emptyUser: UserInfo = {
  id: "",
  name: "",
  surname: "",
  thName: "",
  thSurname: "",
  email: "",
  phone: "",
  faculty: "",
  department: "",
  isStaff: false,
  address: "",
  authenticated: false,
};

const InfoContext = createContext({} as InfoContruct);
export const globalUser = {
  accessToken: "",
};

export const updateUserInfo = (data: any, setUserData: Function) => {
  const {
    id,
    name,
    surname,
    thName,
    thSurname,
    email,
    phone,
    faculty,
    department,
  } = data;
  console.log(data);

  const tmp: UserInfo = {
    id,
    name,
    surname,
    thName: thName ? thName : name,
    thSurname: thSurname ? thSurname : surname,
    email,
    phone,
    faculty,
    department,
    authenticated: true,
  };
  if (STAFF_LIST.some((e) => e === id)) tmp.isStaff = true;
  console.log("[END] success to fetch user data");
  setUserData(tmp);
};

const InfoProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [userData, setUserData] = useState<UserInfo>({} as UserInfo);
  const value = { userData, setUserData, emptyUser };
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const signout = httpClient.interceptors.response.use(undefined, (error) => {
      if (error?.response?.status === 401) {
        logout();

        setUserData(() => emptyUser);
      }
      return Promise.reject(error);
    });

    return () => {
      httpClient.interceptors.response.eject(signout);
    };
  }, []);
  useEffect(() => {
    //case restart window
    if (localStorage.getItem("isAuth") && !userData.authenticated) {
      setisLoading(true);
      getLoginTicket()
        .then((response) => {
          const token = response.data;
          localStorage.setItem("isAuth", token);
          getUserInfo(token).then((e: any) =>
            updateUserInfo(e.data, setUserData)
          );
        })
        .catch(() => {
          console.log("[ERROR] refectch fail");
          logout();
        })
        .finally(() => {
          setisLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    //do some thing with data

    //case login
    if (
      localStorage.getItem("isAuth") &&
      userData.authenticated &&
      !userData.name
    ) {
      console.log("[FETCHING] loggin fetching");

      setisLoading(true);
      getUserInfo(localStorage.getItem("isAuth") || "")
        .then((e: any) => {
          updateUserInfo(e.data, setUserData);
        })
        .catch((e) => {
          console.log("[ERROR] fail to fetch user data");
          logout();
          setUserData(emptyUser);
        })
        .finally(() => {
          setisLoading(false);
        });
    }

    // eslint-disable-next-line
  }, [userData.authenticated]);

  return (
    <InfoContext.Provider value={value}>
      {isLoading && <Loader />}
      {children}
    </InfoContext.Provider>
  );
};
export default InfoProvider;

export function useInfoContext(): InfoContruct {
  return useContext(InfoContext);
}
