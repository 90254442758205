import { Col, Layout } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { useInfoContext } from "../../Providers/UserProvider";

import { useHistory } from "react-router-dom";
import { logout } from "../../utils/api/auth";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  isCollapsed: boolean | undefined;
  setCollapsed: Function;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const Header: React.FC<Props> = ({ isCollapsed, setCollapsed }) => {
  const { Header } = Layout;
  const { userData, setUserData, emptyUser } = useInfoContext();
  const { name, surname, id } = userData;

  const history = useHistory();

  const clearUserData = () => {
    setUserData(emptyUser);
  };

  const handleLogoutSSO = async () => {
    try {
      await logout();
      clearUserData();
      history.push("/login");
    } catch (e) {
      // TODO : handle error
    }
  };

  return (
    <Header
      className="site-layout-background"
      style={{ padding: 0, backgroundColor: "white" }}
    >
      <div
        style={{
          width: "90%",
          display: "flex",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <span>
          <MenuUnfoldOutlined onClick={() => setCollapsed(!isCollapsed)} />
        </span>
        <div style={{ width: "100%" }}>
          <Col
            sm={0}
            style={{
              marginLeft: "20px",
              fontWeight: "bold",
            }}
          >
            ระบบจัดการและนำส่งเอกสาร สำนักทะเบียนจุฬาลงกรณ์มหาวิทยาลัย
          </Col>
          {
            <div style={{ float: "right" }}>
              <span>{name + " " + surname + " " + id}</span>
              <span
                style={{
                  marginLeft: "20px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={handleLogoutSSO}
              >
                <b>LOGOUT</b>
              </span>
            </div>
          }
        </div>
      </div>
    </Header>
  );
};
export default Header;
