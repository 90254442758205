import { Tag } from "antd";

export const previousDocumentHeader: any = (
  handlePdfView: Function,
  student?: Boolean
) =>
  [
    {
      title: "หมายเลขเอกสาร",
      dataIndex: "docID",
      key: "docID",
      align: "center",
      responsive: ["sm"],
    },
    {
      title: "รหัสนิสิต",
      dataIndex: "creator",
      key: "creator",
      align: "center",
    },
    {
      title: "ประเภทเอกสาร",
      dataIndex: "type",
      key: "type",
      align: "center",
    },
    {
      title: "วันที่ขอเอกสาร",
      dataIndex: "requestDate",
      key: "requestDate",
      align: "center",
    },
    {
      title: "วิธีการจัดส่ง",
      dataIndex: "deliverMethod",
      key: "deliverMethod",
      align: "center",
      responsive: ["md"],
    },
    {
      title: "สถานะเอกสาร",
      dataIndex: "currentStep",
      key: "currentStep",
      align: "center",
      render: (text: string, _: any) => {
        const d: any = { Pending: 1, Approved: 2, Rejected: 3 };
        const status: any = d[text];
        return (
          <>
            <Tag
              color={status === 2 ? "green" : status === 1 ? "yellow" : "red"}
            >
              {status === 2
                ? `สำเร็จ`
                : status === 1
                ? `ดำเนินการ`
                : `ไม่สำเร็จ`}
            </Tag>
          </>
        );
      },
    },
    {
      title: "เอกสาร",
      dataIndex: "download",
      key: "download",
      align: "center",
      render: (text: string, row: any) => (
        // eslint-disable-next-line
        <a onClick={() => handlePdfView(row.docID)}>download</a>
      ),
    },
    // if !student filter index 1 out
    // if student return all
  ].filter((_, idx) => student || (!student && idx !== 1));

export const myDocumentHeader = [
  {
    title: "รหัสเอกสาร",
    dataIndex: "title",
    align: "center",
    width: "10%",
    fontWeight: "bold",
  },
  {
    title: "คำอธิบายเอกสาร",
    dataIndex: "th",
    align: "center",
    width: "50%",
    fontWeight: "bold",
  },
  {
    title: "Document Description",
    dataIndex: "en",
    align: "center",
    width: "40%",
    fontWeight: "bold",
    responsive: ["sm"],
  },
];

export const staffTableHeader = [
  {
    title: "หมายเลขเอกสาร",
    dataIndex: "docID",
    align: "center",
    responsive: ["sm"],
  },
  {
    title: "ประเภทเอกสาร",
    dataIndex: "type",
    align: "center",
  },
  {
    title: "รหัสนิสิต",
    dataIndex: "creator",
    align: "center",
  },
  {
    title: "ชื่อ-สกุล",
    dataIndex: "creatorName",
    align: "center",
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "requestDate",
    align: "center",
  },
  {
    title: "วิธีการส่ง",
    dataIndex: "deliverMethod",
    align: "center",
    responsive: ["sm"],
  },
  {
    title: "หมายเลขการโอน",
    dataIndex: "paymentID",
    align: "center",
    responsive: ["sm"],
  },
];
