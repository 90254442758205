import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON41, normalText12 } from "../../utils";

export default function step7({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON41 {
	return {
		"7_ความเห็นอธิการบดี": normalText12(values.input["ความเห็นอธิการบดี"]),
		"7_นามอธิการบดี": normalText12(userData.name + " " + userData.surname),
		"7_อธิการบดี_อนุมัติ": {
			isLocked: false,
			action: "Radio",
			data: 0,
			meta: ["อนุมัติ", "ไม่อนุมัติ"],
		},
		"7_ตำแหน่งอธิการบดี": normalText12("อธิการบดี"),
		"7_วันลงนามอธิการบดี": dateFormat(new Date()),
	};
}

export const reject = ({
	userData,
	reason,
}: {
	userData: UserInfo;
	reason: string;
}) => {
	return {
		"7_ความเห็นอธิการบดี": normalText12(reason),
		"7_นามอธิการบดี": normalText12(userData.name + " " + userData.surname),
		"7_อธิการบดี_อนุมัติ": {
			isLocked: false,
			action: "Radio",
			data: 1,
			meta: ["อนุมัติ", "ไม่อนุมัติ"],
		},
		"7_ตำแหน่งอธิการบดี": normalText12("อธิการบดี"),
		"7_วันลงนามอธิการบดี": dateFormat(new Date()),
	};
};
