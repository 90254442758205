import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON49, normalText12 } from "../../utils";
export default function step7({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON49 {
	return {
		"7_สำนักงานทะเบียน": normalText12(values.input["สำนักงานทะเบียน"]),
		"7_วันลงนามสำนักงานทะเบียน": dateFormat(new Date()),
		"7_สำนักงานทะเบียนลงนาม": normalText12(
			userData.name + " " + userData.surname
		),
		"7_นามสำนักทะเบียน": normalText12(
			userData.name + " " + userData.surname
		),
		"7_สำนักงานการทะเบียน_เลขที่รับ": normalText12(
			values.input["สำนักงานการทะเบียน"]
		),
		"7_สำนักงานการทะเบียน_วันที่รับ": dateFormat(new Date()),
		"7_สำนักงานการทะเบียน_เวลา": normalText12(
			new Date().toLocaleTimeString("th")
		),
		"7_สำนักงานการทะเบียน_ผู้รับ": normalText12(
			userData.name + " " + userData.surname
		),
	};
}
