import React from "react";
import JT32 from "./JT32/Components";
import JT33 from "./JT33/Components";
import JT41 from "./JT41/Components";
import JT49 from "./JT49/Components";
import JT50 from "./JT50/Components";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
	templateCode: string;
	step: number;
	JSON: { [key: string]: { data: string } };
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const FormSelector: React.FC<Props> = ({ templateCode, step, JSON }) => {
	if (templateCode === "32") return <JT32 step={step} data={JSON} />;
	if (templateCode === "33") return <JT33 step={step} data={JSON} />;
	if (templateCode === "41") return <JT41 step={step} data={JSON} />;
	if (templateCode === "49") return <JT49 step={step} data={JSON} />;
	if (templateCode === "50") return <JT50 step={step} data={JSON} />;
	return <>Not found template Code {templateCode}</>;
};

export default FormSelector;
