import {
  Modal,
  notification,
  AutoComplete,
  Form,
  Input,
  ModalProps,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useInfoContext } from "../Providers/UserProvider";
import {
  approveDocument,
  patchDocument,
  sendDocument,
} from "../utils/api/document";
import Iframe from "react-iframe";

import { httpClient } from "../utils/api/http";

import { getUsersList } from "../utils/api/user";
import { renderItem } from "./renderItemStaff";
import ControllerSelector from "../Forms/ControllerSelector";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface UserModalProps {
  setIsModalVisible: (e: boolean) => void;
  value: any;
  header: string;
  templateCode: string;
  step: number;
  redirect: string;
  lastStep?: number;
}
type Props = ModalProps & UserModalProps;

//-------------------------------------------------------------------------//
// summary : component render section
//-------------------------------------------------------------------------//
const ConfirmDocModal: React.FC<Props> = (props) => {
  const { userData } = useInfoContext();
  const [staff, setStaff] = useState({ id: "", value: "" });
  const [staffoptions, setStaffOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const uuid = new URLSearchParams(useLocation().search).get("id") as string;

  const selectTemplate = (template: string) =>
    ControllerSelector({
      templateCode: template,
      step: props.step,
      userData,
      values: props.value,
    });

  const [fileURL, setfileURL] = useState("");

  useEffect(() => {
    if (props.step && props.visible) {
      getUsersList({}).then((data) => {
        const tmp = data
          .filter((e) => e.id !== userData.id)
          .map((e) => renderItem(`${e.name} ${e.surname}`, e.id));
        setStaffOptions(tmp);
      });

      //to patch document
      const options: any = selectTemplate(props.templateCode);
      if (!options) {
        notification["error"]({
          message: "ไม่สามารถบันทึกเอกสารได้",
          description:
            "มีบางอย่างผิดปกติ ระบบไม่สามารถบันทึกเอกสารของคุณได้ กรุณาติดต่อเจ้าหน้าที่",
        });
        return;
      }
      const txt = JSON.stringify(options);
      console.log(
        "🚀 ~ file: ConfirmDocModal.tsx ~ line 79 ~ useEffect ~ txt",
        JSON.parse(txt)
      );
      patchDocument(uuid, { json: txt })
        .then(() => {
          console.log("Success to patch DOC ");
        })
        .catch((e) => {
          if (
            e.response.data.message === "patchData not aligned to the syntax"
          ) {
            notification["error"]({
              message: "ไม่สามารถบันทึกเอกสารได้",
              description:
                "มีบางอย่างผิดปกติ ระบบไม่สามารถบันทึกเอกสารของคุณได้ กรุณาติดต่อเจ้าหน้าที่",
            });
          }

          if (e.response.status === 403) {
            notification["error"]({
              message: "ไม่สามารถบันทึกเอกสารได้",
              description: "เอกสารนี้ได้ถูกยกเลิกไปแล้ว",
            });
          }
        })
        .finally(() => {
          httpClient
            .get(`/document/${uuid}`, {
              withCredentials: true,
              responseType: "blob",
            })
            .then((res) => {
              //Create a Blob from the PDF Stream
              const file = new Blob([res?.data], {
                type: "application/pdf",
              });
              //Build a URL from the file
              const myfileURL = URL.createObjectURL(file);
              //Open the URL on new Window

              setfileURL(myfileURL);
            })
            .catch((e) => {
              // history.push(`/staff`);
            });
        });
    }

    // eslint-disable-next-line
  }, [props.step, props.value, props.visible]);
  const handleOk = useCallback(async () => {
    setLoading(true);

    try {
      if (props.step !== props.lastStep && staff?.id) {
        await sendDocument(uuid, { target: staff?.id });
      } else {
        await approveDocument(uuid);
      }
    } catch (e) {
      // TODO : handle error

      console.log("[ERROR]", e);
    } finally {
      props.setIsModalVisible(false);
      console.log("Success to send the DOC to", staff);
      notification["success"]({
        message: "สำเร็จ",
        description:
          "คุณได้ส่งเอกสารเรียบร้อยแล้ว สามารถตรวจสอบสถานะของเอกสารได้ที่ กล่องประวัติการส่งเอกสาร (Documents History)",
      });

      history.push(props.redirect);
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [props, staff]);
  function handleChange(value: string, option: any) {
    setStaff(option);
  }

  return (
    <Modal {...props} onOk={handleOk} confirmLoading={loading}>
      <div>
        {props.step !== props.lastStep ? (
          <>
            <p>
              {props.header}{" "}
              {!staff.id && (
                <p style={{ color: "red" }}>กรุณาเลือกผู้รับเอกสารให้ถูกต้อง</p>
              )}
            </p>
            <Form.Item
              name={["input", "reciever"]}
              rules={[
                {
                  required: true,
                  message: "กรุณาเลือกผู้รับเอกสาร",
                },
              ]}
              initialValue={1}
            >
              <AutoComplete
                dropdownClassName="certain-category-search-dropdown"
                options={staffoptions}
                filterOption={(inputValue, option) =>
                  option!.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={handleChange}
              >
                <Input.Search size="large" placeholder="ค้นหา" />
              </AutoComplete>
            </Form.Item>
          </>
        ) : (
          <>
            กดยืนยันเพื่อรองรับว่าเอกสารนี้ได้ผ่านการตรวจสอบอย่างถี่ถ้วนแล้ว
            ระบบจะเก็บเอกสารนี้เข้าเซิฟเวอร์และไม่สามารถแก้ไขได้
          </>
        )}
        <Iframe url={fileURL} width="100%" height="200px" />
        <br />
      </div>
    </Modal>
  );
};
export default ConfirmDocModal;
