type OptionsType = {
  [Key: string]: number[];
};
export const options: OptionsType = {
  "32": [2, 3, 4, 5, 6, 7],
  "33": [2],
  "41": [2, 3, 4, 5, 6, 7, 8],
  "50": [1],
};

export const avaliableDoc = [32, 33, 41, 50];
