import { notification } from "antd";
import { createContext, useEffect, useContext } from "react";

import { httpClient } from "../utils/api/http";
import jwt_decode from "jwt-decode";
import { getLoginTicket, logout } from "../utils/api/auth";
import { useInfoContext, emptyUser } from "./UserProvider";
const globalObject = { counter: 0 };
export interface HttpConstruct {
  exchanged: boolean;
}
const HttpContext = createContext({} as HttpConstruct);

const HttpProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setUserData } = useInfoContext();
  useEffect(() => {
    const logger = httpClient.interceptors.request.use(async (data) => {
      let token = localStorage.getItem("isAuth");
      let tokenData: any;
      try {
        tokenData = jwt_decode(token || "");
      } catch {
        setUserData(() => emptyUser);
      }
      // check if accessToken was expired then renew accessToken
      if (tokenData && Date.now() >= tokenData?.exp * 1000) {
        try {
          token = await getLoginTicket()
            .then((response) => {
              console.log("[RENEW]  success exchanged ticket");
              const newToken = response.data;
              localStorage.setItem("isAuth", newToken);
              return newToken;
            })
            .catch((e) => {
              console.log("[ERROR]  can not exchange ticket");
              logout();
              setUserData(() => emptyUser);
            });
        } catch (e) {
          // refreshToken was expired or invalid refreshToken
          console.log("[ERROR]  can not exchange ticket");
          logout();
          setUserData(() => emptyUser);
        }
      }
      data.headers["Authorization"] = `Bearer ${token}`;
      globalObject.counter += 1;
      console.info(
        `METHOD ${globalObject.counter} : `,
        data.method,
        data.url,
        data.method === "get" ? (data.params ? data.params : "") : data.data
      );
      return data;
    });

    const serverError = httpClient.interceptors.response.use(
      (response) => {
        // setLoading(false);
        return response;
      },
      (error) => {
        // setLoading(false);
        console.log(error);
        if (!error.response) {
          notification["error"]({
            message: "มีปัญหาเกี่ยวกับการเชื่อมต่อ",
            description:
              "กรุณาเช็คการเชื่อมต่ออินเตอร์เน็ตของคุณ หรือลองใหม่อีกครั้งภายหลัง",
          });
        }
        if (error?.response?.data?.message && error?.status < 404) {
          notification["error"]({
            message: "มีบางอย่างผิดปกติ",
            description: error?.response?.data?.message,
          });
        }
        if (error?.response?.status >= 500) {
          notification["error"]({
            message: "เซิฟเวอร์ไม่ทำงาน",
            description:
              "เกิดความผิดปกติกับเซิฟเวอร์ กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่",
          });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      httpClient.interceptors.request.eject(logger);
      httpClient.interceptors.response.eject(serverError);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <HttpContext.Provider value={{ exchanged: false }}>
      {children}
    </HttpContext.Provider>
  );
};

export default HttpProvider;

export function useHttpContext(): HttpConstruct {
  return useContext(HttpContext);
}
