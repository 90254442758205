import { Card, notification } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { avaliableDoc } from "../../Forms/config";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  code: number;
  th: string;
  en: string;
  title: string;
  openPostModal: (code: string) => void;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const DocumentCard: React.FC<Props> = ({
  code,
  title,
  th,
  en,
  openPostModal,
}) => {
  const clickHandler = () => {
    if (avaliableDoc.some((e) => e === code)) {
      openPostModal(code.toString());
    } else {
      notification["error"]({
        message: "ยังไม่พร้อมใช้งาน",
        description: "เอกสารนี้ยังไม่เปิดให้ใช้งาน กรุณาลองอีกครั้งภายหลัง",
      });
    }
  };

  return (
    <Card
      onClick={clickHandler}
      title={"แบบฟอร์ม " + title}
      hoverable={true}
      headStyle={{
        background: "linear-gradient(to right,#ffb6cd,#ffd3e1)",
        fontWeight: "bold",
        textAlign: "center",
      }}
      bodyStyle={{ backgroundColor: "#FBFAFA", height: "140px" }}
      style={{
        maxWidth: 300,
        marginRight: "auto",
        marginLeft: "auto",
        marginBottom: "20px",
        zIndex: 0,
      }}
      actions={[<EyeOutlined />]}
    >
      <p>{th}</p>
      <p>{en}</p>
    </Card>
  );
};

export default DocumentCard;
