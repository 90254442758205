import DeclineButton from "../../../Components/forms/DeclineButton";
import FilesButton from "../../../Components/forms/FilesButton";
import ReasonBox from "../../../Components/forms/ReasonBox";
import SendButton from "../../../Components/forms/SendButton";
import { DocumentJSONTypes } from "../../utils";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  data: DocumentJSONTypes;
}

export default function Step2(props: Props) {
  return (
    <>
      <ReasonBox label="สำนักงานทะเบียน" step={2} data={props.data} />
      <FilesButton step={2} templateCode="33" />
      <SendButton label="ดำเนินการได้" />
      <DeclineButton step={2} templateCode="33" />
    </>
  );
}
