import { Layout, Menu } from "antd";
import {
  MenuFoldOutlined,
  BookOutlined,
  UserOutlined,
  LogoutOutlined,
  AuditOutlined,
  FormOutlined,
  FolderOpenOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import RegOrg from "../../assets/Images/regOrg.png";
import { useHistory, useLocation } from "react-router-dom";
import { useInfoContext } from "../../Providers/UserProvider";
import { logout } from "../../utils/api/auth";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  isCollapsed: boolean | undefined;
  setCollapsed: Function;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//

const Sider: React.FC<Props> = ({ isCollapsed, setCollapsed }) => {
  const history = useHistory();
  const { Sider } = Layout;
  const collapsedHandler = () => {
    setCollapsed(!isCollapsed);
  };
  const location = useLocation();
  const pth = location.pathname.split("/").slice(0, 3).join("/");
  const navigateHandler = (e: any) => {
    history.push(e.key);
  };

  const { userData, setUserData, emptyUser } = useInfoContext();
  const isStaff = userData.isStaff;
  const clearUserData = () => {
    setUserData(emptyUser);
  };

  const handleLogoutSSO = async () => {
    try {
      await logout();
      clearUserData();
      history.push("/login");
    } catch (e) {
      // TODO : handle error
    }
  };

  return (
    <Sider
      collapsedWidth={0}
      trigger={
        isCollapsed ? null : (
          <div style={{ color: "black", zIndex: 100 }}>
            <MenuFoldOutlined onClick={collapsedHandler} />{" "}
          </div>
        )
      }
      collapsible
      collapsed={isCollapsed}
      onCollapse={collapsedHandler}
      style={{ position: "fixed", height: "100%", zIndex: 10 }}
    >
      <div style={{ padding: "2%" }}>
        <img width="100%" src={RegOrg} alt="RegOrg" />
      </div>
      <Menu style={{ marginTop: "20px" }} defaultSelectedKeys={[pth]}>
        <Menu.Item
          onClick={navigateHandler}
          key="/user/profile"
          icon={<UserOutlined />}
        >
          My Profile
        </Menu.Item>
        <Menu.Item onClick={navigateHandler} key="/" icon={<BookOutlined />}>
          Create Documents
        </Menu.Item>
        <Menu.Item
          onClick={navigateHandler}
          key="/document/draft"
          icon={<AuditOutlined />}
        >
          Draft Documents
        </Menu.Item>

        <Menu.Item
          onClick={navigateHandler}
          key="/document/history"
          icon={<HistoryOutlined />}
        >
          Documents History
        </Menu.Item>
        {isStaff && (
          <>
            <Menu.Item
              onClick={navigateHandler}
              key="/staff"
              icon={<FormOutlined />}
            >
              Staff Manage
            </Menu.Item>
          </>
        )}
        {isStaff && (
          <>
            <Menu.Item
              onClick={navigateHandler}
              key="/staff/history"
              icon={<FolderOpenOutlined />}
            >
              Staff History
            </Menu.Item>
          </>
        )}
        <Menu.Item
          key="10"
          style={{ backgroundColor: "white", color: "red" }}
          icon={<LogoutOutlined />}
          onClick={handleLogoutSSO}
        >
          Log Out
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
export default Sider;
