import FilesButton from "../../../Components/forms/FilesButton";
import ReasonBox from "../../../Components/forms/ReasonBox";
import SendButton from "../../../Components/forms/SendButton";
import { DocumentJSONTypes } from "../../utils";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  data: DocumentJSONTypes;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
export default function Step2(props: Props) {
  return (
    <>
      <ReasonBox label="ความเห็นที่ปรึกษา" step={2} data={props.data} />
      <FilesButton step={2} templateCode="41" />
      <SendButton />
    </>
  );
}
