import React from "react";
import { Form, Input, Table } from "antd";

import { getDraftedData } from "../../utils";

//-------------------------------------------------------------------------//
// summary : component types section
//-------------------------------------------------------------------------//
interface Props {
  items: any;
  setFieldsValue: any;
  getFieldValue: any;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//

const FormTemplate: React.FC<Props> = ({
  items,
  setFieldsValue,
  getFieldValue,
}) => {
  const FormBox = (props: { fieldName: string; end: string }) => {
    const itemName = ["input", `${props.fieldName}_${props.end}`];
    const draftedvalue = getDraftedData(
      `1_${props.fieldName}_${props.end}`,
      items
    );

    return (
      <Form.Item
        initialValue={draftedvalue}
        name={itemName}
        style={{ height: "0" }}
      >
        <Input placeholder={`${props.fieldName} ${props.end}`} />
      </Form.Item>
    );
  };
  const subjects = [
    "ชื่อบิดา",
    "ชื่อมารดา",
    "ชื่อผู้ปกครอง",
    "ชื่อผู้รับรองและค้ำประกัน",
    "เลขบัตรประชาชน",
    "ที่อยู่ตามทะเบียนบ้าน",
    "สถานที่เกิด",
    "วันเกิด",
    "สัญชาติ",
    "ศาสนา",
    "อีเมลล์",
    "เบอร์โทรศัพท์",
    "ข้อมูลวุฒิการศึกษาเดิม",
    "อื่นๆ",
  ];
  const dataSource = subjects.map((e, idx) => ({ key: idx, subject: e }));

  const columns = [
    {
      title: "รายการที่ขอแก้ไข",
      dataIndex: "subject",
      key: "subject",
      width: "20%",
    },
    {
      title: "จาก",
      dataIndex: "form",
      key: "form",
      render: (_: any, item: any) => {
        if (item?.subject === "ข้อมูลวุฒิการศึกษาเดิม") {
          return (
            <>
              <FormBox fieldName={"ชื่อวุฒิเดิม"} end="จาก" />
              <br />
              <FormBox fieldName={"วันสำเร็จการศึกษาเดิม"} end="จาก" />
              <br />
              <FormBox fieldName={"สถาบันเดิม"} end="จาก" />
            </>
          );
        }
        return <FormBox fieldName={item.subject} end="จาก" />;
      },
    },
    {
      title: "เปลี่ยนเป็น",
      dataIndex: "to",
      key: "to",

      render: (_: any, item: any) => {
        if (item?.subject === "ข้อมูลวุฒิการศึกษาเดิม") {
          return (
            <>
              <FormBox fieldName={"ชื่อวุฒิเดิม"} end="เป็น" />
              <br />
              <FormBox fieldName={"วันสำเร็จการศึกษาเดิม"} end="เป็น" />
              <br />
              <FormBox fieldName={"สถาบันเดิม"} end="เป็น" />
            </>
          );
        }
        return <FormBox fieldName={item.subject} end="เป็น" />;
      },
    },
  ];

  return <Table dataSource={dataSource} pagination={false} columns={columns} />;
};

export default FormTemplate;
