import { Button, Space, Upload } from "antd";
import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { patchDocument } from "../../utils/api/document";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  step: number;
  templateCode: string;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const FilesButton: React.FC<Props> = (props) => {
  const uuid = new URLSearchParams(useLocation().search).get("id") as string;
  const dummyRequest = ({ file, onSuccess }: any) => {
    patchDocument(uuid, { file }).then(() => {
      onSuccess("ok");
    });
  };

  const uploadProps = {
    accept: ".pdf,.png,.jpg",
    customRequest: dummyRequest,

    maxCount: 1,
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  return (
    <Space
      direction="vertical"
      style={{ width: "100%", overflow: "hidden", margin: "0 0 25px 0" }}
      size="large"
    >
      <Upload {...uploadProps} listType="picture">
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </Space>
  );
};

export default FilesButton;
