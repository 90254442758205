import { Form, Col, Select, Input, Row } from "antd";
import React from "react";
import FormTemplate from "../../../Components/forms/FormTemplate";
import { DocumentJSONTypes, getDraftedData } from "../../utils";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  data: DocumentJSONTypes;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const Step1: React.FC<Props> = (props) => {
  const { Option } = Select;

  const year = new Date().getUTCFullYear() + 543;
  const yearOptions = [year + 2, year + 1, year, year - 1, year - 2, year - 3];

  return (
    <FormTemplate templateCode="32" data={props.data}>
      <label style={{ fontWeight: "bold" }}>
        มีความประสงค์ขอรักษาสถานภาพการเป็นนิสิต
      </label>
      <Row gutter={[24, 0]}>
        <Col flex="auto">
          <Form.Item
            name={["input", "คงสถานะ_ภาคการศึกษา"]}
            rules={[
              {
                required: true,
                message: "กรุณาเลือกภาคการศึกษา",
              },
            ]}
            initialValue={
              getDraftedData("1_คงสถานะ_ภาคการศึกษา", props.data) || "1"
            }
            label="ภาคการศึกษา"
          >
            <Select>
              <Option value={"1"}>{1}</Option>
              <Option value={"2"}>{2}</Option>
              <Option value={"3"}>{3}</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col flex="auto">
          <Form.Item
            name={["input", "คงสถานะ_ปีการศึกษา"]}
            rules={[
              {
                required: true,
                message: "กรุณาเลือกปีการศึกษา",
              },
            ]}
            label="ปีการศึกษา"
            style={{ width: "100%	" }}
            initialValue={
              getDraftedData("1_คงสถานะ_ปีการศึกษา", props.data) ||
              year.toString()
            }
          >
            <Select>
              {yearOptions.map((e, idx) => (
                <Option key={idx} value={e.toString()}>
                  {e}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Col flex="auto">
        <Form.Item
          name={["input", "คงสถานะ_เนื่องจาก"]}
          label={"เนื่องจาก"}
          rules={[
            {
              required: true,
              message: "กรุณากรอกเหตุผล",
            },
          ]}
          initialValue={getDraftedData("1_คงสถานะ_เนื่องจาก", props.data)}
        >
          <Input.TextArea placeholder="เหตุผลประกอบ" />
        </Form.Item>
      </Col>
    </FormTemplate>
  );
};

export default Step1;
