import { DatePicker, Form } from "antd";

import moment from "moment";

import { DocumentJSONTypes } from "../../utils";
import SendButton from "../../../Components/forms/SendButton";
import DeclineButton from "../../../Components/forms/DeclineButton";
import FilesButton from "../../../Components/forms/FilesButton";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  data: DocumentJSONTypes;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const getDraftedDate = (date: string[]): Date => {
  if (date[0] === "") return new Date();
  let [day, month, year] = date;
  const newDay = parseInt(day);
  const newMonth = parseInt(month);
  const newYear = parseInt(year) - 543;
  console.log(newYear, newMonth, newDay);

  return new Date(newYear, newMonth - 1, newDay);
};

export default function Step4(props: Props) {
  const draftedDate = getDraftedDate(
    props.data["4_วันส่งธีสิส"].data as Array<string>
  );
  const dateFormatList = "DD/MM/YYYY";
  const momentDate = props.data["4_ส่งธีสิสสมบูรณ์"].data
    ? moment(draftedDate, dateFormatList)
    : undefined;
  // const momentDate = props.data["4_ส่งธีสิสสมบูรณ์"].data
  // 	? moment(draftedDate, dateFormatList)
  // 	: moment(new Date(), dateFormatList);

  return (
    <>
      <Form.Item
        style={{ marginBottom: "30px" }}
        name={["input", "graduatedDate"]}
        label={<div>ส่งวิทยานิพนธ์ฉบับสมบูรณ์</div>}
        initialValue={momentDate}
      >
        <DatePicker style={{ width: "100%" }} format={dateFormatList} />
      </Form.Item>
      <FilesButton step={4} templateCode="32" />
      <SendButton />
      <DeclineButton step={4} templateCode="32" />
    </>
  );
}
