import { ComponentType } from "react";
import { Redirect } from "react-router-dom";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
function withAuthGuard<P>(Component: ComponentType<P>) {
  return (props: P) => {
    const isLoggedIn = localStorage.getItem("isAuth");
    if (!isLoggedIn) return <Redirect to="/login" />;
    return <Component {...props} />;
  };
}

export default withAuthGuard;
