import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON41, normalText12 } from "../../utils";

const getDate = (d: Date) => [
	d.getDate().toString(),
	(d.getMonth() + 1).toString(),
	(d.getFullYear() + 543).toString(),
];

export default function step7({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON41 {
	return {
		"8_สำนักงานทะเบียน": normalText12(values.input["สำนักงานทะเบียน"]),
		"8_นามสำนักทะเบียน": normalText12(
			userData.name + " " + userData.surname
		),
		"8_วันลงนามสำนักงานทะเบียน": dateFormat(new Date()),
		"8_สำนักงานการทะเบียน_วันที่รับ": normalText12(
			getDate(new Date()).join("/")
		),
		"8_สำนักงานการทะเบียน_เวลา": normalText12(
			new Date().toLocaleTimeString("th")
		),
		"8_สำนักงานการทะเบียน_ผู้รับ": normalText12(
			userData.name + " " + userData.surname
		),
	};
}
