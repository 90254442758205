import { Col, Row } from "antd";
import DocumentCard from "./DocumentCard";

interface Props {
  items: any[];
  openPostModal: (code: string) => void;
}

const Card: React.FC<Props> = ({ items, openPostModal }) => {
  return (
    <Row>
      {items.map((item, idx) => {
        const { title, en, th, code } = item;
        return (
          <Col key={idx} xs={24} sm={24} md={6} lg={6} xl={6}>
            <DocumentCard
              openPostModal={openPostModal}
              title={title}
              th={th}
              en={en}
              code={code}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default Card;
