import { Form, Modal, ModalProps, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import RejectController from "../Forms/RejectController";
import { useInfoContext } from "../Providers/UserProvider";
import { patchDocument, rejectDocument } from "../utils/api/document";

// import { useHistory } from "react-router-dom";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface ConfirmPostModalProps {
  step: number;
  setIsModalVisible: (e: boolean) => void;
  templateCode: string;
}
type Props = ModalProps & ConfirmPostModalProps;

//-------------------------------------------------------------------------//
// summary : component render section
//-------------------------------------------------------------------------//
const DeclineModal: React.FC<Props> = (props) => {
  const closeModal = () => {
    props.setIsModalVisible(false);
  };
  const { userData } = useInfoContext();
  const [reason, setReason] = useState("");
  const history = useHistory();
  const uuid = new URLSearchParams(useLocation().search).get("id") as string;
  const okHandler = async () => {
    //Allocate Document
    try {
      //to patch document
      const options = RejectController({
        templateCode: props.templateCode,
        step: props.step,
        reason,
        userData,
      });
      const txt = JSON.stringify(options);
      console.log(
        "🚀 ~ file: ConfirmDocModal.tsx ~ line 79 ~ useEffect ~ txt",
        JSON.parse(txt)
      );
      patchDocument(uuid, { json: txt })
        .then(() => {
          console.log("Success to patch DOC ");
        })
        .catch((e) => {
          if (
            e.response.data.message === "patchData not aligned to the syntax"
          ) {
            notification["error"]({
              message: "ไม่สามารถบันทึกเอกสารได้",
              description:
                "มีบางอย่างผิดปกติ ระบบไม่สามารถบันทึกเอกสารของคุณได้ กรุณาติดต่อเจ้าหน้าที่",
            });
          }
          if (e.response.status === 403) {
            notification["error"]({
              message: "ไม่สามารถบันทึกเอกสารได้",
              description: "เอกสารนี้ได้ถูกยกเลิกไปแล้ว",
            });
          }
        });

      //to reject document
      await rejectDocument(uuid, {
        rejectedReason: reason,
      });

      notification["success"]({
        message: "สำเร็จ",
        description: "คุณได้ยกเลิกเอกสารเรียบร้อยแล้ว",
      });
      history.push(`/staff`);
    } catch {
      notification["error"]({
        message: "ไม่สำเร็จ",
        description: "ไม่สามารถยกเลิกเอกสารนี้ได้กรุณาติดต่อเจ้าหน้าที่",
      });
      history.push(`/staff`);
    } finally {
      closeModal();
    }
  };

  return (
    <Modal {...props} onOk={okHandler} onCancel={() => closeModal()}>
      <div>
        <Form.Item
          style={{ marginBottom: "30px" }}
          name={["เหตุผลที่ยกเลิกเอกสาร"]}
          label={"กรุณากรอกเหตุผลที่ยกเลิกเอกสาร"}
          initialValue={reason}
        >
          <TextArea
            showCount
            onChange={(e: any) => {
              setReason(e.target.value);
            }}
            maxLength={300}
          />
        </Form.Item>
      </div>
    </Modal>
  );
};

export default DeclineModal;
