import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON50, normalText12 } from "../../utils";
export default function step2({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON50 {
	return {
		"2_เจ้าหน้าาที่ชำระเงินลงนาม": normalText12(
			userData.name + " " + userData.surname
		),
		"2_สำนักงานการทะเบียน_เวลา": normalText12(
			new Date().toLocaleTimeString("th")
		),
		"2_สำนักงานการทะเบียน_เลขที่รับ": normalText12(
			values.input["สำนักงานการทะเบียน"]
		),
		"2_สำนักงานการทะเบียน_ผู้รับ": normalText12(
			userData.name + " " + userData.surname
		),
		"2_สำนักงานการทะเบียน_วันที่รับ": dateFormat(new Date()),
	};
}
