import { Button, Row, Col, Divider, Typography } from "antd";
import { API_HOST } from "../../utils/config";
import BgImage from "../../assets/Images/BgImage.jpg";

const { Title } = Typography;

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//

interface AnnouncementPaperProps {
  header: string;
  body: string;
}

const AnnouncementPaper = (props: AnnouncementPaperProps) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "5px",
        padding: "2%",
      }}
    >
      <div>
        <h2>{props.header}</h2>
      </div>
      <div>{props.body}</div>
    </div>
  );
};

function Login() {
  const handleLogin = () => {
    window.location.href = `${API_HOST}/auth/sso`;
  };

  const loremIpsum =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

  const announce = [
    {
      header: "Announcement Header",
      body: loremIpsum,
    },
    {
      header: "Announcement Header",
      body: loremIpsum,
    },
    {
      header: "Announcement Header",
      body: loremIpsum,
    },
  ];

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${BgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <Row>
        <Col xs={0} lg={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingTop: "5%",
              paddingBottom: "5%",
              height: "100vh",
            }}
          >
            {announce.map((i) => {
              return <AnnouncementPaper header={i.header} body={i.body} />;
            })}
          </div>
        </Col>

        <Col xs={24} lg={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "80%",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "5px",
                padding: "2%",
              }}
            >
              <Divider>
                <Title level={3}>เข้าสู่ระบบ</Title>
              </Divider>
              <Button
                type="primary"
                onClick={handleLogin}
                block
                style={{
                  marginBottom: "20px",
                }}
              >
                Login via ChulaSSO
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
