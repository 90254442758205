import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import UserProvider from "./Providers/UserProvider";

// eslint-disable-next-line

import Layout from "./Components/layout/Layout";
import Login from "./Pages/auth/Login";
import HttpProvider from "./Providers/HttpProvider";
import Auth from "./Pages/auth/Auth";

function App() {
  return (
    <UserProvider>
      <HttpProvider>
        <Router>
          <Switch>
            <Route exact path="/auth" component={Auth} />
            <Route exact path="/login" component={Login} />
            <Route path="/" component={Layout} />
          </Switch>
        </Router>
      </HttpProvider>
    </UserProvider>
  );
}

export default App;
