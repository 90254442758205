import { Layout } from "antd";
import Sider from "../common/Sider";
import { useState } from "react";
import Header from "../common/Header";
import MainRouter from "../../Pages/MainRouter";
import withAuthGuard from "../../Guards/auth.guard";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
function MainPage() {
  const [isCollapse, setCollapse] = useState(true);
  const toggleCollapsed = () => setCollapse(!isCollapse);
  const { Content } = Layout;
  const FreeAreaToCollapse = () => (
    <div
      onClick={() => setCollapse(!isCollapse)}
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 2,
      }}
    ></div>
  );

  return (
    <Layout style={{ minHeight: "100%", zIndex: 0 }}>
      <Sider isCollapsed={isCollapse} setCollapsed={setCollapse} />
      {!isCollapse && <FreeAreaToCollapse />}
      <Layout onClick={() => (!isCollapse ? toggleCollapsed : null)}>
        <Header isCollapsed={isCollapse} setCollapsed={setCollapse} />
        <Content
          className="site-layout-background"
          style={{
            backgroundColor: "white",
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <MainRouter />
        </Content>
      </Layout>
    </Layout>
  );
}

export default withAuthGuard(MainPage);
