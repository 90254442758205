import { Divider, Row, Table } from "antd";
import Loader from "../../Components/common/Loader";
import { previousDocumentHeader } from "../../utils/tableHeader";
import { Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { httpClient } from "../../utils/api/http";
import InfiniteScrollHook from "../../hooks/InfiniteScrollHook";

//-------------------------------------------------------------------------//
// summary :  component constance section
//-------------------------------------------------------------------------//
const handlePdfView = async (uuid: string) => {
  httpClient
    .get("/document/" + uuid, {
      withCredentials: true,
      responseType: "blob",
    })
    .then((res) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "application/pdf" });
      //Build a URL from the file
      const myfileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(myfileURL);
    });
};
//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//

function PreviousDocument() {
  const query = {
    isCreator: true,
    isHolder: false,
  };
  const { fetchMoreData, isLoading, items, hasMore } = InfiniteScrollHook({
    query,
  });

  const columns = previousDocumentHeader(handlePdfView);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Divider>
        <div
          style={{
            fontSize: "1.5em",
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          เอกสารที่ผ่านมา
        </div>
      </Divider>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <Row style={{ padding: "50px 0" }} justify="center">
            <Spin />
          </Row>
        }
        endMessage={
          <Row style={{ padding: "50px 0" }} justify="center">
            {`ดูเอกสารทั้งหมดครบแล้ว ${new Date().toString()}`}{" "}
          </Row>
        }
      >
        <Table
          scroll={{ x: true }}
          style={{ width: "100%" }}
          pagination={false}
          size="middle"
          dataSource={items}
          columns={columns}
        ></Table>
      </InfiniteScroll>
    </div>
  );
}

export default PreviousDocument;
