import DeclineButton from "../../../Components/forms/DeclineButton";
import FilesButton from "../../../Components/forms/FilesButton";
import SendButton from "../../../Components/forms/SendButton";
import { DocumentJSONTypes } from "../../utils";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
	data: DocumentJSONTypes;
}

export default function Step2(props: Props) {
	return (
		<>
			<FilesButton step={2} templateCode="50" />
			<SendButton />
			<DeclineButton step={2} templateCode="50" />
		</>
	);
}
