import { Divider } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { getDocumenJSONtByID, getDocumentByID } from "../../utils/api/document";
import Loader from "../../Components/common/Loader";
import useFetch from "../../hooks/FetchHook";
import FormSelector from "../../Forms/FormSelector";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
function CreateDocument() {
  const uuid = new URLSearchParams(useLocation().search).get("id") as string;
  const history = useHistory();

  function fetchJSON() {
    return getDocumenJSONtByID(uuid);
  }
  function fetchINFO() {
    return getDocumentByID(uuid);
  }

  const [documentJSON, isLoadingJSON, isErrorINFO] = useFetch(fetchJSON);
  const [documentINFO, isLoadingINFO, isErrorJSON] = useFetch(fetchINFO);

  if (!uuid || isErrorINFO || isErrorJSON) {
    history.push("/document/draft");
  }

  if (isLoadingJSON || isLoadingINFO) return <Loader />;
  return (
    <div>
      <Divider>
        <div
          style={{
            fontSize: "1.5em",
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "20px",
            fontWeight: "bold",
          }}
        >
          {`สร้างเอกสาร จท${documentINFO?.templateCode?.slice(2)}`}
        </div>
      </Divider>
      <FormSelector
        templateCode={documentINFO?.templateCode?.slice(2)}
        step={1}
        JSON={documentJSON}
      />
    </div>
  );
}

export default CreateDocument;
