export var documentPaper: any = {
  5: {
    code: 5,
    title: "จท 5",
    th: "คำร้องขอเข้าสังกัดหรือเปลี่ยนสังกัด",
    en: "Request Form To Select or Change Field of Study",
  },
  31: {
    code: 31,
    title: "จท 31",
    th: "คำร้องขอลาออก",
    en: "Request Form for Resignation",
  },
  32: {
    code: 32,
    title: "จท 32",
    th: "คำร้องขอรักษาสถานภาพการเป็นนิสิต",
    en: "Request Form for Retaining Student Status",
  },
  33: {
    code: 33,
    title: "จท ​33 ",
    th: "คำร้องขอแก้ไขประวัตินิสิต",
    en: "Request Form for change of Student Record",
  },
  39: {
    code: 39,
    title: "จท 39",
    th: "แบบแจ้งผลการโอนหน่วยกิตรายวิชาต่างสถาบันการศึกษา",
    en: "",
  },
  41: {
    code: 41,
    title: "จท 41",
    th: "คำร้องทั่วไป",
    en: "General Request Form",
  },
  42: {
    code: 42,
    title: "จท 42",
    th: "คำร้องขอใบรับรอง",
    en: "Application for certificates",
  },
  42.1: {
    code: 42.1,
    title: "จท 42/1",
    th: "คำร้องขอใบรับรอง",
    en: "Application for certificates",
  },
  42.2: {
    code: 42.2,
    title: "จท 42/2",
    th: "คำร้องขอรับบริการจัดส่งเอกสารสำคัญทางการศึกษา ทางไปรษณีย์",
    en: "Application for mailing services",
  },
  43: {
    code: 43,
    title: "จท 43",
    th: "คำร้องขอลงทะเบียนเรียนเพื่อขอผลการศึกษาเป็น S/U หรือ V/W",
    en: "Request Form for S/U or V/W Registration",
  },
  44: {
    code: 44,
    title: "จท 44",
    th: "คำร้องขอลาป่วย",
    en: "Request Form for Sick Leave",
  },
  45: {
    code: 45,
    title: "จท 45",
    th: "คำร้องขอรับเงินคืน",
    en: "Application for Refund",
  },
  46: {
    code: 46,
    title: "จท 46",
    th: "ขอลงทะเบียนเรียนโดยมีหน่วยกิตเกินกว่ากำหนดในข้อบังคับจุฬาฯ (ปริญญาตรี)",
    en: "Request Form to Register Above Credits Allowed",
  },
  46.5: {
    code: 46,
    title: "จท 46",
    th: "ขอลงทะเบียนเรียนโดยมีหน่วยกิตเกินกว่ากำหนดในข้อบังคับจุฬาฯ (บัณฑิตศึกษา)",
    en: "Request Form to Register Above Credits Allowed",
  },
  47: {
    code: 47,
    title: "จท 47",
    th: "คำร้องขอเปลี่ยนคำนำหน้าชื่อ ชื่อ-นามสกุล",
    en: "Request form for Name-Surname, Title Change",
  },
  48: {
    code: 48,
    title: "จท 48",
    th: "คำร้องขอถอนรายวิชา (W) หลังกำหนด (ปริญญาตรี)",
    en: "Request Form for Course Withdrawal",
  },
  48.5: {
    code: 48.5,
    title: "จท 48",
    th: "คำร้องขอถอนรายวิชา (W) หลังกำหนด (บัณฑิตศึกษา)",
    en: "Request Form for Course Withdrawal",
  },
  49: {
    code: 49,
    title: "จท 49",
    th: "คำร้องขอลาพักการศึกษา",
    en: "Request Form for Leave of Absence",
  },
  50: {
    code: 50,
    title: "จท 50",
    th: "คำร้องขอทำบัตรประจำตัวนิสิต",
    en: "Request Form for a Student Identification Card",
  },
};
