import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON32, normalText12 } from "../../utils";

export default function step5({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}) {
	return {
		"5_คณะบดีอนุมัติ": {
			isLocked: false,
			action: "Radio",
			data: 0,
			meta: ["อนุมัติ", "ไม่อนุมัติ"],
		},
		"5_ไม่อนุมัติเพราะ": normalText12(values.input?.reason),
		"5_นามคณบดี": normalText12(userData.name + " " + userData.surname),
		"5_วันลงนามคณบดี": dateFormat(new Date()),
	};
}

export const reject = ({
	userData,
	reason,
}: {
	userData: UserInfo;
	reason: string;
}): JSON32 => {
	return {
		"5_คณะบดีอนุมัติ": {
			isLocked: false,
			action: "Radio",
			data: 1,
			meta: ["อนุมัติ", "ไม่อนุมัติ"],
		},
		"5_ไม่อนุมัติเพราะ": normalText12(reason),
		"5_นามคณบดี": normalText12(userData.name + " " + userData.surname),
		"5_วันลงนามคณบดี": dateFormat(new Date()),
	};
};
