import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Dropdown, Form, Menu, Row } from "antd";
import { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../Components/common/Loader";
import FormLayout from "../../Components/layout/FormLayout";
import { options } from "../../Forms/config";
import FormSelector from "../../Forms/FormSelector";
import ConfirmDocModal from "../../Modals/ConfirmDocModal";
import { useInfoContext } from "../../Providers/UserProvider";
import { getDocumenJSONtByID, getDocumentByID } from "../../utils/api/document";
import { httpClient } from "../../utils/api/http";
import { documentPaper } from "../../utils/documents";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
function StaffApprove() {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [pdf, setMySrc] = useState("");
	const { userData } = useInfoContext();
	const [value, setValue] = useState({});
	const [docInfo, setDocinfo] = useState<any>({});
	const [fileURL, setfileURL] = useState("");
	const [form] = Form.useForm();
	const [step, setStep] = useState(0);
	const history = useHistory();
	const [docJSON, setdocJSON] = useState({});
	const uuid = new URLSearchParams(useLocation().search).get("id") as string;
	const submitHandler = (e: any) => {
		setValue({ ...e });
		showModal();
	};
	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	useEffect(() => {
		httpClient
			.get(`/document/${uuid}`, {
				withCredentials: true,
				responseType: "blob",
			})
			.then((res) => {
				setMySrc(res?.data);
			})
			.catch((e) => {
				history.push(`/staff`);
			});
		// eslint-disable-next-line
	}, [userData]);

	useEffect(() => {
		getDocumentByID(uuid).then((item) => {
			setDocinfo(item);
		});
		getDocumenJSONtByID(uuid).then((item) => {
			console.log(item);

			setdocJSON(item);
		});
		// eslint-disable-next-line
	}, [userData]);

	useEffect(() => {
		if (pdf) {
			//Create a Blob from the PDF Stream
			const file = new Blob([pdf], { type: "application/pdf" });
			//Build a URL from the file
			const myfileURL = URL.createObjectURL(file);
			//Open the URL on new Window

			setfileURL(myfileURL);
		}
	}, [pdf]);

	const count = options[docInfo?.templateCode?.slice(2)] || [];

	const menu = (
		<Menu
			onClick={(e: any) => {
				setStep(parseInt(e.key));
			}}
		>
			{count.map((e: number) => (
				<Menu.Item key={e} icon={<UserOutlined />}>
					ขั้นตอนที่ {e}
				</Menu.Item>
			))}
		</Menu>
	);

	if (!fileURL && !docInfo?.templateCode?.slice(2)) return <Loader />;
	return (
		<>
			<ConfirmDocModal
				title={
					<span style={{ fontWeight: "bold" }}>
						ยืนยันการส่งเอกสาร (ขั้นตอนที่ {step})
					</span>
				}
				lastStep={count[count.length - 1]}
				step={step}
				templateCode={docInfo.templateCode?.slice(2)}
				visible={isModalVisible}
				onCancel={handleCancel}
				setIsModalVisible={setIsModalVisible}
				value={value}
				header="เลือกอาจารย์"
				redirect="/staff"
			/>

			<FormLayout>
				<Divider>
					<div
						style={{
							fontSize: "1.5em",
							textAlign: "center",
							marginTop: "10px",
							marginBottom: "10px",
							fontWeight: "bold",
						}}
					>
						อนุมัติเอกสาร
					</div>
				</Divider>
				<Row>
					<Col xs={0} sm={0} md={16} lg={16} xl={16}>
						<Iframe url={fileURL} width="100%" height="700px" />
					</Col>
					<Col xs={24} sm={24} md={0} lg={0} xl={0}>
						<div
							style={{
								width: "100%",
								display: "flex",
								marginTop: "10%",
								marginBottom: "10%",
								justifyContent: "center",
							}}
						>
							<a href={fileURL} download>
								<Button>Click here to download PDF</Button>
							</a>
						</div>
					</Col>
					<Col xs={24} sm={24} md={8} lg={8} xl={8}>
						<div
							style={{
								width: "100%",
								display: "flex",

								textAlign: "center",

								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
							}}
						>
							<Form
								form={form}
								onFinish={submitHandler}
								layout="vertical"
								style={{ width: "80%" }}
							>
								<Form.Item
									label={
										<label style={{ fontWeight: "bold" }}>
											ผู้ขอเอกสาร
										</label>
									}
								>
									<div>{`${docInfo?.creator?.id} ${docInfo?.creator?.name} ${docInfo?.creator?.surname}`}</div>
								</Form.Item>
								<Form.Item
									label={
										<label style={{ fontWeight: "bold" }}>
											ประเภทเอกสาร {<DownOutlined />}
										</label>
									}
								>
									{` ${(() => {
										const doc =
											documentPaper[
												parseFloat(
													docInfo?.templateCode.slice(
														2
													)
												)
											];
										return `${doc.title} : ${doc.th}`;
									})()}`}
								</Form.Item>
								<Divider />
								<div style={{ marginBottom: "5%" }}>
									<Dropdown overlay={menu}>
										<Button
											style={{
												width: "100%",
											}}
										>
											{step === 0
												? "กรุณาเลือกขั้นตอน"
												: `ขั้นตอนที่ ${step}`}{" "}
											<DownOutlined />
										</Button>
									</Dropdown>
								</div>
								<FormSelector
									templateCode={docInfo?.templateCode.slice(
										2
									)}
									step={step}
									JSON={docJSON}
								/>
							</Form>
						</div>
					</Col>
				</Row>
			</FormLayout>
		</>
	);
}

export default StaffApprove;
