import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, getUserInput, normalText12, studentID } from "../../utils";

export default function step1({
  // userData,
  values,
}: {
  userData: UserInfo;
  values: any;
}) {
  const data: { [key: string]: any } = {};

  Object.keys(values?.input).forEach((e: any) => {
    if (typeof e === "string") {
      data[`1_${e}`] = normalText12(values.input[e]);
    }
  });

  const subjects = [
    "ชื่อบิดา",
    "ชื่อมารดา",
    "ชื่อผู้ปกครอง",
    "ชื่อผู้รับรองและค้ำประกัน",
    "เลขบัตรประชาชน",
    "ที่อยู่ตามทะเบียนบ้าน",
    "สถานที่เกิด",
    "วันเกิด",
    "สัญชาติ",
    "ศาสนา",
    "อีเมลล์",
    "เบอร์โทรศัพท์",
    "ชื่อวุฒิเดิม",
    "วันสำเร็จการศึกษาเดิม",
    "สถาบันเดิม",
    "อื่นๆ",
  ];
  const checkIsUserFill = (name: string): boolean => {
    if (values.input[`${name}_จาก`] && values.input[`${name}_เป็น`])
      return true;
    return false;
  };
  subjects.forEach(
    (e) =>
      (data[`1_${e}`] = {
        isLocked: false,
        action: "Checkbox",
        data: checkIsUserFill(e),
      })
  );
  if (
    checkIsUserFill("ชื่อวุฒิเดิม") ||
    checkIsUserFill("วันสำเร็จการศึกษาเดิม") ||
    checkIsUserFill("สถาบันเดิม")
  ) {
    data["1_ข้อมูลวุฒิการศึกษาเดิม"] = {
      isLocked: false,
      action: "Checkbox",
      data: true,
    };
  } else {
    data["1_ข้อมูลวุฒิการศึกษาเดิม"] = {
      isLocked: false,
      action: "Checkbox",
      data: false,
    };
  }
  return {
    ...data,
    "1_ระบบการศึกษา": {
      isLocked: false,
      action: "Radio",
      data: values.input.ระบบการศึกษา,

      meta: ["ทวิภาค/ทวิภาค-นานาชาติ", "ตรีภาค"],
    },
    "1_นิสิตระดับ": {
      isLocked: false,
      action: "Radio",
      data: values.input.นิสิตระดับ,

      meta: ["ปริญญาตรี", "บัณฑิตศึกษา"],
    },
    "1_วันลงนามนิสิต": dateFormat(new Date()),
    "1_รหัสนิสิต": studentID(getUserInput(values, "รหัสนิสิต")),
  };
}
