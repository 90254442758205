import React from "react";
import { DocumentJSONTypes } from "../../utils";
import Step1 from "./Step1";
import Step2 from "./Step2";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  step: number;
  data: DocumentJSONTypes;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const index: React.FC<Props> = ({ step, data }) => {
  if (step === 1) return <Step1 data={data} />;
  if (step === 2) return <Step2 data={data} />;
  return <></>;
};

export default index;
