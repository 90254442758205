import { Divider, Table, Row, Spin, Input, Col } from "antd";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import Loader from "../../Components/common/Loader";
import InfiniteScrollHook from "../../hooks/InfiniteScrollHook";

import { httpClient } from "../../utils/api/http";
import { previousDocumentHeader } from "../../utils/tableHeader";
//-------------------------------------------------------------------------//
// summary :  component constance section
//-------------------------------------------------------------------------//
const { Search } = Input;

const handlePdfView = async (uuid: string) => {
  httpClient
    .get("/document/" + uuid, {
      withCredentials: true,
      responseType: "blob",
    })
    .then((res) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "application/pdf" });
      //Build a URL from the file
      const myfileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(myfileURL);
    });
};

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
function StaffHistory() {
  const [creator, setCreator] = useState("");
  const columns = previousDocumentHeader(handlePdfView, true);

  const query: any = {
    hasHeld: true,
  };
  if (creator !== "") {
    query.creatorId = creator;
  } else {
    query.isCreator = false;
  }
  const { fetchMoreData, isLoading, items, hasMore } = InfiniteScrollHook({
    query,
  });
  const onSearch = (value: any) => {
    setCreator(value);
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Divider>
        <div
          style={{
            fontSize: "1.5em",
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          เอกสารในระบบ
        </div>
      </Divider>
      <Row justify="center" style={{ padding: "0px 0 25px 0" }}>
        <Col flex="auth" xs={24} sm={24} md={12} style={{ maxWidth: "1024px" }}>
          <Search
            defaultValue={creator}
            allowClear
            placeholder="กรอกรหัสนิสิตเพื่อค้นหา"
            onSearch={onSearch}
            enterButton
          />
        </Col>
      </Row>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <Row style={{ padding: "50px 0" }} justify="center">
            <Spin />
          </Row>
        }
        endMessage={
          <Row style={{ padding: "50px 0" }} justify="center">
            {`ดูเอกสารทั้งหมดครบแล้ว ${new Date().toString()}`}{" "}
          </Row>
        }
      >
        <Table
          scroll={{ x: true }}
          style={{ width: "100%" }}
          pagination={false}
          size="middle"
          dataSource={items}
          columns={columns}
        ></Table>
      </InfiniteScroll>
    </div>
  );
}

export default StaffHistory;
