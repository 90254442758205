import { UserOutlined } from "@ant-design/icons";
export const renderItem = (title: string, id: string) => ({
  value: title,
  id: id,
  label: (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {title}
      <span>
        <UserOutlined />
      </span>
    </div>
  ),
});
