import axios from "axios";
import { API_HOST } from "../config";
import { get, put } from "./http";

export interface UserInfo {
  affiliates: string[];
  roles: string[];
  id: string;
  name: string;
  surname: string;
  email: string;
  department: string;
  faculty: string;
  lastLogin: Date;
}

export interface getUsersDtos {
  role?: string;
  affiliate?: string;
  name?: string;
  "except-roles"?: string;
}

//-------------------------------------------------------------------------//
// TODO : get staff list that sugest user
//-------------------------------------------------------------------------//
export async function getUsersList(data: getUsersDtos): Promise<UserInfo[]> {
  return await get(`/user`, data);
}
//-------------------------------------------------------------------------//
// TODO : get staff list that sugest user
//-------------------------------------------------------------------------//
export async function updateUserProfile(data: any): Promise<UserInfo> {
  return await put(`/user/me`, data);
}

//-------------------------------------------------------------------------//
// TODO : get user's details
//-------------------------------------------------------------------------//
export async function getUserInfo(token: string): Promise<UserInfo> {
  return await axios.get(`${API_HOST}/user/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
