import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON49, normalText12 } from "../../utils";
export default function step4({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON49 {
	return {
		"4_ทะเบียนคณะ_เลขที่รับ": normalText12(values.input["ทะเบียนคณะ"]),
		"4_ทะเบียนคณะ_วันที่รับ": dateFormat(new Date()),
		"4_ทะเบียนคณะ_เวลา": normalText12(new Date().toLocaleTimeString("th")),
		"4_ทะเบียนคณะ_ผู้รับ": normalText12(
			userData.name + " " + userData.surname
		),
		"4_นายทะเบียนคณะลงนาม": normalText12(
			userData.name + " " + userData.surname
		),
		"4_นามนายทะเบียนคณะ": normalText12(
			userData.name + " " + userData.surname
		),
		"4_วันลงนามนายทะเบียนคณะ": dateFormat(new Date()),
		"4_ความเห็นทะเบียนคณะ": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: [
				"เสนอคณบดีเพื่ออนุมัติตามระเบียบ",
				"เสนอกรรมการประจำคณะตามระเบียบ",
				"เห็นชอบ",
			],
		},
		"4_เสนอคณบดีเพื่ออนุมิตตามระเบียบข้อ": normalText12(
			values.input["เสนอคณบดีเพื่ออนุมิตตามระเบียบข้อ"]
		),
	};
}
