import { Button, Form } from "antd";
import React, { useState } from "react";
import DeclineModal from "../../Modals/DeclineDocModalt";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  label?: string;
  step: number;
  templateCode: string;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//

const DeclineButton: React.FC<Props> = ({
  label = "ไม่อนุมัติเอกสาร",
  step,
  templateCode,
}) => {
  const [state, setState] = useState(false);
  const handleClick = (e: any) => {
    setState(true);
  };
  return (
    <>
      <DeclineModal
        setIsModalVisible={setState}
        visible={state}
        title={
          <span style={{ fontWeight: "bold", color: "red" }}>ยกเลิกเอกสาร</span>
        }
        step={step}
        templateCode={templateCode}
      />
      <Form.Item>
        <Button onClick={handleClick} type={"dashed"} block>
          {label}
        </Button>
      </Form.Item>
    </>
  );
};
export default DeclineButton;
