import React from "react";
import { DocumentJSONTypes } from "../../utils";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  step: number;
  data: DocumentJSONTypes;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const index: React.FC<Props> = ({ step, data }) => {
  if (step === 1) return <Step1 data={data} />;
  if (step === 2) return <Step2 data={data} />;
  if (step === 3) return <Step3 data={data} />;
  if (step === 4) return <Step4 data={data} />;
  if (step === 5) return <Step5 data={data} />;
  if (step === 6) return <Step6 data={data} />;
  if (step === 7) return <Step7 data={data} />;
  if (step === 8) return <Step8 data={data} />;
  return <></>;
};

export default index;
