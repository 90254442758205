import React, { useEffect } from "react";
import { getLoginTicket } from "../../utils/api/auth";
import { useInfoContext } from "../../Providers/UserProvider";
import { useHistory } from "react-router-dom";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
export default function Auth() {
	const { setUserData } = useInfoContext();
	const history = useHistory();
	useEffect(() => {
		getLoginTicket()
			.then((response) => {
				// console.log("set token : ", response.data);
				localStorage.setItem("isAuth", response.data);
				setUserData((prevState) => ({
					...prevState,
					authenticated: true,
				}));
				history.push("/");
			})
			.catch(() => {
				history.push("/login");
			});
	}, [setUserData, history]);
	return <>success</>;
}
