import { Form, Col, Row } from "antd";
import React from "react";
import FormTemplate from "../../../Components/forms/FormTemplate";
import { DocumentJSONTypes, getDraftedData } from "../../utils";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
	data: DocumentJSONTypes;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const Step1: React.FC<Props> = (props) => {

	return (
		<FormTemplate templateCode="49" data={props.data}>
			<label style={{ fontWeight: "bold" }}>
				หัวข้อ
			</label>
			<Row gutter={[24, 0]}>
				<Col flex="auto">
				
				</Col>
			</Row>
		</FormTemplate>
	);
};

export default Step1;