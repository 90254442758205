import { UserInfo } from "../Providers/UserProvider";
import jt32 from "./JT32/Controllers";
import jt33 from "./JT33/Controllers";
import jt41 from "./JT41/Controllers";
import jt49 from "./JT49/Controllers";
import jt50 from "./JT50/Controllers";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
	templateCode: string;
	step: number;
	userData: UserInfo;
	values: any;
}
//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const ControllerSelector: any = ({
	templateCode,
	step,
	userData,
	values,
}: Props) => {
	if (templateCode === "32") return jt32({ index: step, userData, values });
	if (templateCode === "33") return jt33({ index: step, userData, values });
	if (templateCode === "41") return jt41({ index: step, userData, values });
	if (templateCode === "49") return jt49({ index: step, userData, values });
	if (templateCode === "50") return jt50({ index: step, userData, values });
};

export default ControllerSelector;
