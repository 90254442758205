import ReasonBox from "../../../Components/forms/ReasonBox";
import SendButton from "../../../Components/forms/SendButton";
import React from "react";
import { DocumentJSONTypes } from "../../utils";
import FilesButton from "../../../Components/forms/FilesButton";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  data: DocumentJSONTypes;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const Step2: React.FC<Props> = (props) => {
  return (
    <>
      <ReasonBox label="ความเห็นอาจารย์" step={2} data={props.data} />
      <FilesButton step={2} templateCode="32" />
      <SendButton />
    </>
  );
};

export default Step2;
