import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON32, normalText12 } from "../../utils";

export default function step3({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON32 {
	if (values.input.ความเห็นหัวหน้าภาควิชา) {
		return {
			"3_ตรี_ความเห็นหัวหน้าภาควิชา": normalText12(
				values.input["ความเห็นหัวหน้าภาควิชา"]
			),
			"3_ตรี_นามหัวหน้าภาควิชา": normalText12(
				userData.name + " " + userData.surname
			),
			"3_ตรี_วันลงนามหัวหน้าภาควิชา": dateFormat(new Date()),
		};
	}
	return {
		"3_โท_ความเห็นกรรมการหลักสูตร": normalText12(
			values.input["ความเห็นกรรมการหลักสูตร"]
		),
		"3_โท_นามกรรมการหลักสูตร": normalText12(
			userData.name + " " + userData.surname
		),
		"3_โท_วันลงนามกรรมการหลักสูตร": dateFormat(new Date()),
	};
}
