import FilesButton from "../../../Components/forms/FilesButton";
import ReasonBox from "../../../Components/forms/ReasonBox";
import SendButton from "../../../Components/forms/SendButton";
import { DocumentJSONTypes, getDraftedData } from "../../utils";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  data: DocumentJSONTypes;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
export default function Step3(props: Props) {
  const type = getDraftedData("1_นิสิตระดับ", props.data);

  return (
    <>
      {type === 0 ? (
        <ReasonBox
          label="ความเห็นหัวหน้าภาควิชา"
          step={3}
          data={props.data}
          forceInitName="3_ตรี_ความเห็นหัวหน้าภาควิชา"
        />
      ) : (
        <ReasonBox
          label="ความเห็นกรรมการหลักสูตร"
          step={3}
          data={props.data}
          forceInitName="3_โท_ความเห็นกรรมการหลักสูตร"
        />
      )}
      <FilesButton step={3} templateCode="32" />
      <SendButton />
    </>
  );
}
