import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON50, normalText12 } from "../../utils";
export default function step3({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON50 {
	return {
		"3_นิสิตลงนาม": normalText12(userData.name + " " + userData.surname),
		"3_วันลงนามนิสิต": dateFormat(new Date()),
		"3_ทำบัตรใหม่เนื่องจาก": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: ["สญหาย", "ออกบัตรครั้งแรก", "ชำรุด", "อื่นๆ", "หมดอายุ"],
		},
	};
}
