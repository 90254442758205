import { Col, Form, FormItemProps, Input } from "antd";
import React from "react";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
type Props = {
  initialValue: string;
  name: string;
  label: string;
} & FormItemProps;

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const TextBoxInfo: React.FC<Props> = ({
  initialValue,
  name,
  label,

  ...props
}) => {
  return (
    <Col flex="auto" md={12}>
      <Form.Item
        initialValue={initialValue}
        name={["input", name]}
        label={<label style={{ fontWeight: "bold" }}>{label}</label>}
        {...props}
      >
        <Input />
      </Form.Item>
    </Col>
  );
};

export default TextBoxInfo;
