import React, { useState } from "react";
import { Form, Button, Divider, Row, Col, Select } from "antd";
import { useInfoContext } from "../../../Providers/UserProvider";
import ConfirmDocModal from "../../../Modals/ConfirmDocModal";

import { DocumentJSONTypes, getDraftedData } from "../../utils";
import Loader from "../../../Components/common/Loader";
import FormLayout from "../../../Components/layout/FormLayout";
import TextBoxInfo from "../../../Components/forms/TextBoxInfo";
import FormTable from "./FormTable";
import FilesButton from "../../../Components/forms/FilesButton";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  data: DocumentJSONTypes;
}
const { Option } = Select;
//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const Header: React.FC<Props> = (props) => {
  const { userData } = useInfoContext();
  const { thName, thSurname, department, faculty, id, phone, email } = userData;

  const year = new Date().getUTCFullYear() + 543;
  const yearOptions = [year + 2, year + 1, year, year - 1, year - 2, year - 3];

  const [form] = Form.useForm();
  const [value, setValue] = useState<number>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const onFinish = (e: any) => {
    setValue(e);

    showModal();
  };
  if (!userData.id) {
    return <Loader />;
  }
  return (
    <>
      <ConfirmDocModal
        title={<span style={{ fontWeight: "bold" }}>ยืนยันการส่งเอกสาร</span>}
        templateCode={"33"}
        visible={isModalVisible}
        onCancel={handleCancel}
        setIsModalVisible={setIsModalVisible}
        value={value}
        header="เลือกเจ้าหน้าที่ทะเบียน"
        step={1}
        redirect="/document/history"
      />

      <FormLayout>
        <Form layout="vertical" form={form} onFinish={onFinish} name="form">
          <Row gutter={[24, 0]}>
            <TextBoxInfo
              initialValue={
                getDraftedData("1_ชื่อนามสกุล", props.data) ||
                thName + " " + thSurname
              }
              name="ชื่อนามสกุล"
              label="ชื่อ - นามสกุล"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกชื่อ-สกุล",
                },
              ]}
            />
            <TextBoxInfo
              initialValue={getDraftedData("1_รหัสนิสิต", props.data) || id}
              name="รหัสนิสิต"
              label="รหัสประจำตัวนิสิต"
              required
              rules={[
                {
                  len: 10,
                  message: "รหัสนิสิตต้องมี 10 ตัวอักษร",
                },
              ]}
            />
          </Row>
          <Row gutter={[24, 0]}>
            <TextBoxInfo
              initialValue={getDraftedData("1_คณะ", props.data) || faculty}
              name="คณะ"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกคณะ",
                },
              ]}
              label="คณะ"
            />
            <TextBoxInfo
              initialValue={
                getDraftedData("1_ภาควิชา", props.data) || department
              }
              name="ภาควิชา"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกภาควิชา",
                },
              ]}
              label="ภาควิชา"
            />
          </Row>
          <Row gutter={[24, 0]}>
            <TextBoxInfo
              initialValue={getDraftedData("1_รหัสนิสิต", props.data) || email}
              name="Email"
              label="E-mail"
            />
            <TextBoxInfo
              initialValue={getDraftedData("1_โทรศัพท์", props.data) || phone}
              name="โทรศัพท์"
              label="โทรศัพท์"
              rules={[
                {
                  len: 10,
                  message: "เบอร์มือถือต้องเป็น 10 ตัวเลข",
                },
              ]}
            />
          </Row>
          <Row gutter={[24, 0]}>
            <Col flex="auto">
              <Form.Item
                name={["input", "ภาคการศึกษา"]}
                rules={[
                  {
                    required: true,
                    message: "กรุณาเลือกภาคการศึกษา",
                  },
                ]}
                label="ภาคการศึกษา"
                initialValue={
                  getDraftedData("1_ภาคการศึกษา", props.data) || "1"
                }
              >
                <Select>
                  <Option value={"1"}>{1}</Option>
                  <Option value={"2"}>{2}</Option>
                  <Option value={"3"}>{3}</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col flex="auto">
              <Form.Item
                name={["input", "ปีการศึกษา"]}
                rules={[
                  {
                    required: true,
                    message: "กรุณาเลือกปีการศึกษา",
                  },
                ]}
                label="ปีการศึกษา"
                style={{ width: "100%	" }}
                initialValue={
                  getDraftedData("1_ปีการศึกษา", props.data) ||
                  yearOptions[0].toString()
                }
              >
                <Select>
                  {yearOptions.map((e, idx) => (
                    <Option key={idx} value={e.toString()}>
                      {e}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <Form.Item
                name={["input", "ระบบการศึกษา"]}
                rules={[
                  {
                    required: true,
                    message: "กรุณาเลือกระบบการศึกษา",
                  },
                ]}
                label="ระบบการศึกษา"
                initialValue={getDraftedData("1_ระบบการศึกษา", props.data)}
              >
                <Select>
                  <Option value={0}>ทวิภาค / ทวิภาค-นานาชาติ</Option>
                  <Option value={1}>ตรีภาค</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col flex="auto">
              <Form.Item
                name={["input", "นิสิตระดับ"]}
                rules={[
                  {
                    required: true,
                    message: "กรุณาเลือกนิสิตระดับ",
                  },
                ]}
                label="นิสิตระดับ"
                style={{ width: "100%" }}
                initialValue={getDraftedData("1_นิสิตระดับ", props.data)}
              >
                <Select>
                  <Option value={0}>ปริญญาตรี</Option>
                  <Option value={1}>บัณฑิตศึกษา</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <FormTable
            items={props.data}
            setFieldsValue={form.setFieldsValue}
            getFieldValue={form.getFieldValue}
          />
          <br />

          <Row justify="center">
            <Col xs={24}>
              <FilesButton step={1} templateCode={"33"} />
            </Col>
            <Col flex="auto" md={24} lg={14}>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  ส่งเอกสาร
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FormLayout>
    </>
  );
};

export default Header;
