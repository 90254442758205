import { UserInfo } from "../../../Providers/UserProvider";
import {
	dateFormat,
	getUserInput,
	JSON49,
	normalText12,
	studentID,
} from "../../utils";
export default function step1({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON49 {
	return {
		"1_ภาคการศึกษา": normalText12(values.input["ภาคการศึกษา"]),
		"1_ปีการศึกษา": normalText12(values.input["ปีการศึกษา"]),
		"1_ระบบการศึกษา": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: ["ทวิภาค", "ทวิภาค-นานาชาติ", "ตรีภาค"],
		},
		"1_นิสิตระดับ": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: ["ปริญญาตรี", "บัณฑิตศึกษา"],
		},
		"1_รหัสนิสิต": studentID(getUserInput(values, "รหัสนิสิต")),
		"1_ชื่อนามสกุล": normalText12(userData.name + " " + userData.surname),
		"1_Email": normalText12(values.input["Email"]),
		"1_ภาควิชา": normalText12(values.input["ภาควิชา"]),
		"1_โทรศัพท์": normalText12(values.input["โทรศัพท์"]),
		"1_คณะ": normalText12(values.input["คณะ"]),
		"1_GPAX": normalText12(values.input["GPAX"]),
		"1_มีความประสงค์ขอลาพักการศึกษาเป็นจำนวน": normalText12(
			values.input["มีความประสงค์ขอลาพักการศึกษาเป็นจำนวน"]
		),
		"1_มีความประสงค์ขอลาพักการศึกษา_ตั้งแต่_ภาค": normalText12(
			values.input["มีความประสงค์ขอลาพักการศึกษา"]
		),
		"1_มีความประสงค์ขอลาพักการศึกษา_ถึง_ภาค": normalText12(
			values.input["มีความประสงค์ขอลาพักการศึกษา"]
		),
		"1_มีความประสงค์ขอลาพักการศึกษา_ถึง_ปีการคึกษา": normalText12(
			values.input["มีความประสงค์ขอลาพักการศึกษา"]
		),
		"1_ใบรำรองแพทย์จากสถานพยาบาลชื่อ": normalText12(
			values.input["ใบรำรองแพทย์จากสถานพยาบาลชื่อ"]
		),
		"1_เอกสารประกอบถ้ามี": normalText12(values.input["เอกสารประกอบถ้ามี"]),
		"1_นิสิตลงนาม": normalText12(userData.name + " " + userData.surname),
		"1_วันลงนามนิสิต": dateFormat(new Date()),
		"1_สถานภาพการเป็นนิสิต": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: ["ปกติ", "วิทยาทัณฑ์"],
		},
		"1_มีความประสงค์ขอลาพักการศึกษา_ตั้งแต่_ปีการคึกษา": normalText12(
			values.input["มีความประสงค์ขอลาพักการศึกษา"]
		),
		"1_วิทยาทัณฑ์ครั้งที่": normalText12(
			values.input["วิทยาทัณฑ์ครั้งที่"]
		),
		"1_พร้อมนี้ได้แนบหลักฐาน": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: ["ใบรับรองแพทย์จากสถานพยาบาล", "สาเหตุอื่น"],
		},
		"1_สาเหตุอื่น": normalText12(values.input["สาเหตุอื่น"]),
		"1_ใบรำรองแพทย์จากสถานพยาบาลวันที่": dateFormat(new Date()),
	};
}
