import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON49, normalText12 } from "../../utils";
export default function step5({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON49 {
	return {
		"5_ความเห็นคณะบดี": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: ["อนุมัติ", "ไม่อนุมัติ"],
		},
		"5_คณะบดีลงนาม": normalText12(userData.name + " " + userData.surname),
		"5_วันลงนามคณบดี": dateFormat(new Date()),
		"5_นามคณบดี": normalText12(userData.name + " " + userData.surname),
		"5_ไม่อนุมัติเพราะ": normalText12(values.input["ไม่อนุมัติเพราะ"]),
	};
}
