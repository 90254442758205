import { Form, Col, Input } from "antd";
import FormTemplate from "../../../Components/forms/FormTemplate";
import { DocumentJSONTypes, getDraftedData } from "../../utils";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  data: DocumentJSONTypes;
}
//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const JT41: React.FC<Props> = ({ data }) => {
  return (
    <FormTemplate templateCode="41" data={data}>
      <div style={{ marginBottom: "1%" }}>
        <label style={{ fontWeight: "bold" }}>
          มีความประสงค์ (พร้อมเหตุผลประกอบ)
        </label>
      </div>

      <Col flex="auto">
        <Form.Item
          name={["input", "มีความประสงค์"]}
          rules={[
            {
              required: true,
              message: "กรุณากรอกเหตุผล",
            },
          ]}
          initialValue={getDraftedData("1_มีความประสงค์", data)}
        >
          <Input.TextArea placeholder="ความประสงค์พร้อมเหตุผลประกอบ" />
        </Form.Item>
      </Col>
    </FormTemplate>
  );
};

export default JT41;
