import { Button, Form } from "antd";
import React from "react";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  label?: string;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const SendButton: React.FC<Props> = ({ label = "ส่งต่อเอกสาร" }) => {
  return (
    <Form.Item>
      <Button htmlType="submit" type={"primary"} block>
        {label}
      </Button>
    </Form.Item>
  );
};
export default SendButton;
