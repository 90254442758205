import { Divider, Table, Row, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import Loader from "../../Components/common/Loader";
import InfiniteScrollHook from "../../hooks/InfiniteScrollHook";

//-------------------------------------------------------------------------//
// summary :  component constance section
//-------------------------------------------------------------------------//
const columns: any[] = [
  {
    title: "หมายเลขเอกสาร",
    dataIndex: "docID",
    align: "center",
  },
  {
    title: "ประเภทเอกสาร",
    dataIndex: "type",
    align: "center",
  },

  {
    title: "วันที่สร้าง",
    dataIndex: "requestDate",
    align: "center",
  },
];

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
function DraftDocument() {
  const history = useHistory();

  const query: any = {
    isCreator: true,
    isHolder: true,
  };

  const { fetchMoreData, isLoading, items, hasMore } = InfiniteScrollHook({
    query,
  });

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Divider>
        <div
          style={{
            fontSize: "1.5em",
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          เอกสารในระบบ
        </div>
      </Divider>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <Row style={{ padding: "50px 0" }} justify="center">
            <Spin />
          </Row>
        }
        endMessage={
          <Row style={{ padding: "50px 0" }} justify="center">
            {`ดูเอกสารทั้งหมดครบแล้ว ${new Date().toString()}`}{" "}
          </Row>
        }
      >
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                history.push(`/document/edit?id=${record.docID}`);
              }, // click row
            };
          }}
          style={{ width: "100%" }}
          pagination={false}
          dataSource={items}
          columns={columns}
        />
      </InfiniteScroll>
    </div>
  );
}

export default DraftDocument;
