import JT32 from "../../generator/json/CR32.json";
import JT41 from "../../generator/json/CR41.json";
import JT33 from "../../generator/json/jt33.json";
import JT49 from "../../generator/json/jt49.json";
import JT50 from "../../generator/json/jt50.json";
interface InputType {
	[key: string]: string;
}

export const getUserInput = (
	data: { input: InputType },
	target: string
): string => {
	return data.input[target];
};

export const getDraftedData = (key: string, data: any) => {
	const info = data[key]?.data;
	if (typeof info === "number") return info > 0 ? info : 0;
	if (!Array.isArray(info)) return;
	if (info.length === 0) return;
	if (info.length === 1) return info[0];
	return info.join("");
};

export interface DocumentJSONTypes {
	[key: string]: { data: string | boolean | string[] };
}
export const studentID = (id: string) => {
	return {
		isLocked: false,
		action: "WriteText",
		data: id.split(""),
	};
};

export const normalText12 = (text: string) => {
	text = text ? text : "";
	return {
		isLocked: false,
		action: "WriteText",
		data: [text],
	};
};

export const dateFormat = (realDate: Date) => {
	const day = realDate.getDate().toString();
	const month = (realDate.getMonth() + 1).toString();
	const year = (realDate.getFullYear() + 543).toString();

	return {
		isLocked: false,
		action: "WriteText",
		data: [day, month, year],
	};
};

export type JSON32 = Partial<typeof JT32>;
export type JSON33 = Partial<typeof JT33>;
export type JSON41 = Partial<typeof JT41>;
export type JSON49 = Partial<typeof JT49>;
export type JSON50 = Partial<typeof JT50>;
