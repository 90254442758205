import React from "react";
import Card from "./TableTypeCard";
import TableList from "./TableTypeList";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  display: number;
  items: any[];
  openPostModal: (code: string) => void;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const TableShowOptions: React.FC<Props> = ({
  display,
  items,
  openPostModal,
}) => {
  if (display) {
    return <TableList items={items} openPostModal={openPostModal} />;
  }
  return <Card items={items} openPostModal={openPostModal} />;
};

export default TableShowOptions;
