import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON32, normalText12 } from "../../utils";

const getDate = (d: Date) => [
	d.getDate().toString(),
	(d.getMonth() + 1).toString(),
	(d.getFullYear() + 543).toString(),
];

export default function step4({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON32 {
	const d = values.input.graduatedDate;
	let dateList;

	if (d) {
		const realDate = new Date(d._d);

		const [day, month, year] = getDate(realDate);
		dateList = [day, month, year];
	} else {
		dateList = ["", "", ""];
	}

	return {
		"4_วันส่งธีสิส": {
			isLocked: false,
			action: "WriteText",
			data: dateList,
		},
		"4_ส่งธีสิสสมบูรณ์": {
			isLocked: false,
			action: "Checkbox",
			data: !!values.input?.graduatedDate,
		},
		"4_เห็นควรอนุมัติ": {
			isLocked: false,
			action: "Checkbox",
			data: true,
		},
		"4_นามนายทะเบียนคณะ": normalText12(
			userData.name + " " + userData.surname
		),
		"4_นายทะเบียนคณะลงนาม": normalText12(
			userData.name + " " + userData.surname
		),
		"4_วันลงนามนายทะเบียนคณะ": dateFormat(new Date()),
		"4_ทะเบียนคณะ_วันที่รับ": normalText12(getDate(new Date()).join("/")),
		"4_ทะเบียนคณะ_เวลา": normalText12(new Date().toLocaleTimeString("th")),
		"4_ทะเบียนคณะ_ผู้รับ": normalText12(
			userData.name + " " + userData.surname
		),
	};
}

export const reject = ({
	userData,
	reason,
}: {
	userData: UserInfo;
	reason: string;
}) => {
	return {
		"4_เห็นควรอนุมัติ": {
			isLocked: false,
			action: "Checkbox",
			data: false,
		},
		"4_นามทะเบียนคณะ": normalText12(userData.name + " " + userData.surname),
		"4_นายทะเบียนคณะลงนาม": normalText12(
			userData.name + " " + userData.surname
		),
		"4_วันลงนามทะเบียนคณะ": dateFormat(new Date()),
		"4_ทะเบียนคณะ_วันที่รับ": normalText12(getDate(new Date()).join("/")),
		"4_ทะเบียนคณะ_เวลา": normalText12(new Date().toLocaleTimeString("th")),
		"4_ทะเบียนคณะ_ผู้รับ": normalText12(
			userData.name + " " + userData.surname
		),
	};
};
