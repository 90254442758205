import { UserInfo } from "../../../Providers/UserProvider";
import {
	dateFormat,
	getUserInput,
	JSON50,
	normalText12,
	studentID,
} from "../../utils";
export default function step1({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON50 {
	return {
		"1_นิสิตระดับ": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: ["ปริญญาตรี", "บัณฑิตศึกษา"],
		},
		"1_ปีการศึกษา": normalText12(values.input["ปีการศึกษา"]),
		"1_ภาคการศึกษา": normalText12(values.input["ภาคการศึกษา"]),
		"1_ภาควิชา": normalText12(values.input["ภาควิชา"]),
		"1_โทรศัพท์": normalText12(values.input["โทรศัพท์"]),
		"1_ทำบัตรใหม่เนื่องจาก": {
			isLocked: false,
			action: "Radio",
			data: -1,
			meta: ["สูญหาย", "ออกบัตรครั้งแรก", "ชำรุด", "อื่นๆ", "หมดอายุ"],
		},
		"1_Email": normalText12(values.input["Email"]),
		"1_ชื่อนามสกุล": normalText12(userData.name + " " + userData.surname),
		"1_คณะ": normalText12(values.input["คณะ"]),
		"1_รหัสนิสิต": studentID(getUserInput(values, "รหัสนิสิต")),
		"1_อื่นๆระบุ": normalText12(values.input["อื่นๆระบุ"]),
		"1_นิสิตลงนาม": normalText12(userData.name + " " + userData.surname),
		"1_วันลงนามนิสิต": dateFormat(new Date()),
	};
}
