import DeclineButton from "../../../Components/forms/DeclineButton";
import SendButton from "../../../Components/forms/SendButton";
import { DocumentJSONTypes } from "../../utils";
import FilesButton from "../../../Components/forms/FilesButton";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
	data: DocumentJSONTypes;
}

export default function Step8(props: Props) {
	return (
		<>
			<FilesButton step={8} templateCode="49" />
			<SendButton />
			<DeclineButton step={8} templateCode="49" />
		</>
	);
}