import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON32, normalText12 } from "../../utils";

export default function step2({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON32 {
	return {
		"2_ความเห็นอาจารย์": normalText12(values.input["ความเห็นอาจารย์"]),
		"2_นามที่ปรึกษา": normalText12(userData.name + " " + userData.surname),
		"2_วันลงนามที่ปรึกษา": dateFormat(new Date()),
	};
}
