import axios from "axios";
import { API_HOST } from "../config";

export async function logout(): Promise<void> {
  localStorage.removeItem("isAuth");

  return await axios.delete(`${API_HOST}/auth`, { withCredentials: true });
}

export async function getLoginTicket(): Promise<any> {
  console.log("[REQUEST] renewing accessToken");
  return await axios.get(`${API_HOST}/auth/token`, { withCredentials: true });
}
