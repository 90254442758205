import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON49, normalText12 } from "../../utils";
export default function step8({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON49 {
	return {
		"8_วันลงนามนายทะเบียน": dateFormat(new Date()),
		"8_นายทะเบียนลงนาม": normalText12(
			userData.name + " " + userData.surname
		),
		"8_นามนายทะเบียน": normalText12(userData.name + " " + userData.surname),
	};
}
