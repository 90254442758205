import axios from "axios";
import { API_HOST } from "../config";

const httpClient = axios.create({
  baseURL: API_HOST,
  withCredentials: true,
});

async function get<T>(url: string, params?: any, headers?: any): Promise<T> {
  return (await httpClient.get(url, { params, headers })).data;
}

async function post<D, T>(url: string, data?: D): Promise<T> {
  return (await httpClient.post(url, data)).data;
}

async function put<D, T>(url: string, data?: D): Promise<T> {
  return (await httpClient.put(url, data)).data;
}

async function del(url: string): Promise<void> {
  await httpClient.delete(url);
}

async function patch<D, T>(url: string, data?: D): Promise<T> {
  return (await httpClient.patch(url, data)).data;
}

export { httpClient, get, post, put, del, patch };
