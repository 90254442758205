import { useEffect, useState } from "react";
import { useInfoContext } from "../Providers/UserProvider";
interface State {
  data: any;
  isLoading: Boolean;
  isError: Boolean;
}
type Props = () => any;
const FetchHook = (fetch: Props) => {
  const [state, setState] = useState<State>({
    data: {},
    isLoading: true,
    isError: false,
  });
  const { userData } = useInfoContext();

  useEffect(() => {
    //fetch only if user logged in
    if (userData.id) {
      fetch()
        .then((data: any) =>
          setState((prev) => ({ ...prev, data, isLoading: false }))
        )
        .catch(() => {
          setState((prev) => ({ ...prev, isError: true }));
        });
    }
    // eslint-disable-next-line
  }, [userData.id]);

  return [state.data, state.isLoading, state.isError];
};
export default FetchHook;
