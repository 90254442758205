import { reject as cr32step4 } from "./JT32/Controllers/step4";
import { reject as cr32step5 } from "./JT32/Controllers/step5";
import { reject as cr41step7 } from "./JT41/Controllers/step7";
import { UserInfo } from "../Providers/UserProvider";

//--------------------------------------------------------------------------//
// summary :  component types section
//--------------------------------------------------------------------------//
interface Props {
  templateCode: string;
  step: number;
  reason: string;
  userData: UserInfo;
}
//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const RejectController = ({ templateCode, step, reason, userData }: Props) => {
  if (templateCode === "32") {
    if (step === 4) return cr32step4({ userData, reason });
    if (step === 5) return cr32step5({ userData, reason });
  }
  if (templateCode === "41") {
    if (step === 7) return cr41step7({ userData, reason });
  }

  return {};
};

export default RejectController;
