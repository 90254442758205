import { get, patch, post } from "./http";

export async function postDocument(
  templateCode: string
): Promise<CreatedDocument> {
  return await post<{ templateCode: string }, CreatedDocument>(`/document`, {
    templateCode,
  });
}

export async function getDocumenJSONtByID(uuid: string): Promise<any> {
  return await get<any>(`/document/${uuid}/data`);
}

export async function getDocumenPDFByID(uuid: string): Promise<any> {
  return await get<any>(`/document/${uuid}`, null, {
    withCredentials: true,
    responseType: "blob",
  });
}

export async function getDocumentByID(uuid: string): Promise<any> {
  return await get<any>(`/document/${uuid}/info`);
}

export async function getDocumentsList(
  query?: documentQuery
): Promise<DocumentEntry[]> {
  return await get<DocumentEntry[]>(`/document`, query);
}

export async function patchDocument(uuid: string, query: any): Promise<void> {
  const bodyFormData = new FormData();

  bodyFormData.append("json", query.json || "{}");

  if (query.file) {
    bodyFormData.append("file", query.file);
  }
  return await patch(`/document/${uuid}/data`, bodyFormData);
}
export async function sendDocument(
  uuid: string,
  body: { target: string }
): Promise<void> {
  return await post<Object, void>(`/document/${uuid}/actions/send`, body);
}

export async function approveDocument(uuid: string): Promise<void> {
  return await post<Object, void>(`/document/${uuid}/actions/approve`, {});
}

export async function rejectDocument(
  uuid: string,
  data: Object
): Promise<void> {
  return await post<Object, void>(`/document/${uuid}/actions/reject`, data);
}

//-------------------------------------------------------------------------//
// TODO : Define document types
//-------------------------------------------------------------------------//
export interface BaseReturnedDocument {
  uuid: string;
  templateCode: string;
  createdAt: Date;
  updatedAt: Date;
  sentAt: Date;
  status: "Pending" | "Approved" | "Rejected" | "Others";
  rejectedReason?: string;
}
export type DocumentEntry = BaseReturnedDocument & {
  holderName: string;
  creatorName: string;
  isHolder: boolean;
  isCreator: boolean;
  creatorId: string;
};
export type CreatedDocument = BaseReturnedDocument & {
  holderName: string;
  creatorName: string;
  holderId: string;
  creatorId: string;
};
export interface documentQuery {
  uuid?: string;
  templateCode?: string;
  holder?: string;
  creatorId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  sentAt?: Date;
  status?: "Pending" | "Approved" | "Rejected" | "Others";
  cursor?: string;
  isHolder?: boolean;
  isCreator?: boolean;
  skip?: number;
  take?: number;
}
