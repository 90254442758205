import { Spin, Space } from "antd";

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
function Loader() {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        zIndex: 1,
      }}
    >
      <Space size="large">
        <Spin size="large" />
      </Space>
    </div>
  );
}

export default Loader;
