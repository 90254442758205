import { useState } from "react";
import { useEffect } from "react";
import { useInfoContext } from "../Providers/UserProvider";
import { getDocumentsList } from "../utils/api/document";
import { documentQuery } from "../utils/api/document";
//-------------------------------------------------------------------------//
// summary :  component constance section
//-------------------------------------------------------------------------//
const TAKE = 15;

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//

interface Props {
  query: documentQuery;
  filterOption?: (e: any) => boolean;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const InfiniteScrollHook = ({ query, filterOption = () => true }: Props) => {
  const [data, setData] = useState<{ items: any[]; hasMore: boolean }>({
    items: [],
    hasMore: false,
  });
  query.take = TAKE;
  const [isLoading, setLoading] = useState(false);
  const { userData } = useInfoContext();
  const [cursor, setCursor] = useState<string>("");
  const updateData = (res: any[], force = false) => {
    const hasMore = !(res.length < TAKE);
    if (res.length > 0) {
      setCursor(res[res.length - 1].uuid);
    } else {
      if (force) setData({ items: [], hasMore: false });
      else setData((prev) => ({ ...prev, hasMore: false }));
      return;
    }

    //need to fix about amount after filter

    if (filterOption) res = res.filter(filterOption);

    console.log(hasMore ? "Has more" : "No more");

    const tmp: any[] = res.map((e, i) => ({
      key: e.uuid,
      docID: e.uuid,
      creator: e.creatorId,
      creatorName: e.creatorName,
      type: e.templateCode,
      requestDate: new Date(e.createdAt).toLocaleDateString(),
      currentStep: e.status,
      paymentID: "12345678912",
      deliverMethod: "EMS",
      isHolder: e.isHolder,
    }));

    setData({ items: force ? tmp : data.items.concat(tmp), hasMore });
  };

  useEffect(() => {
    if (userData.name) {
      setLoading(true);
      getDocumentsList(query)
        .then((res) => {
          updateData(res, true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [userData.name, query.creatorId]);

  const fetchMoreData = () => {
    const fetchMoreQuery = { ...query, cursor, skip: 1 };
    getDocumentsList(fetchMoreQuery).then((res) => {
      updateData(res);
    });
  };

  return {
    items: data.items,
    isLoading,
    fetchMoreData,
    hasMore: data.hasMore,
  };
};

export default InfiniteScrollHook;
