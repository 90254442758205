import { UserInfo } from "../../../Providers/UserProvider";
import step1 from "./step1";
import step2 from "./step2";
import step3 from "./step3";
interface Props {
	index: number;
	userData: UserInfo;
	values: any;
}
const index = ({ index, userData, values }: Props) => {
	if (index === 1) return step1({ userData, values });
	if (index === 2) return step2({ userData, values });
	if (index === 3) return step3({ userData, values });

	return { invalid: true };
};
export default index;
