import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Divider } from "antd";

import { documentPaper } from "../../utils/documents";
import withAuthGuard from "../../Guards/auth.guard";

import ConfirmPostModal from "../../Modals/ConfirmPostModal";
import SelectMenu from "../../Components/document/SelectMenu";
import TableShowOptions from "../../Components/document/TableShowOptions";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
function MyDocument() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentID, setDocumentID] = useState("");
  const history = useHistory();
  const [display, setDisplay] = useState(1);
  const addDocHandler = () => {
    history.push("/");
  };

  const openPostModal = (clickedDocumentID: string) => {
    // console.log(clickedDocumentID);
    setDocumentID(clickedDocumentID);
    setIsModalVisible(true);
  };

  const items = Object.keys(documentPaper).reduce((obj, e, idx) => {
    obj.push({
      code: documentPaper[e]["code"],
      title: documentPaper[e]["title"],
      th: documentPaper[e]["th"],
      en: documentPaper[e]["en"],
      key: idx,
    });
    return obj;
  }, new Array(0));

  return (
    <>
      <ConfirmPostModal
        setIsModalVisible={setIsModalVisible}
        documentID={documentID}
        visible={isModalVisible}
      />
      <div>
        <Divider>
          <div
            onClick={addDocHandler}
            style={{
              fontSize: "1.5em",
              textAlign: "center",
              marginTop: "10px",
              fontWeight: "bold",
            }}
          >
            สร้างเอกสารใหม่
          </div>
        </Divider>

        <SelectMenu display={display} setDisplay={setDisplay} />
        <TableShowOptions
          display={display}
          items={items}
          openPostModal={openPostModal}
        />
      </div>
    </>
  );
}

export default withAuthGuard(MyDocument);
