import React from "react";
import {
  CreditCardOutlined,
  CreditCardFilled,
  DownSquareOutlined,
  DownSquareFilled,
} from "@ant-design/icons";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  setDisplay: (display: number) => void;
  display: number;
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const SelectMenu: React.FC<Props> = ({ setDisplay, display }) => {
  const Menu1 = display ? (
    <CreditCardOutlined
      style={{
        fontSize: "20px",
        cursor: "pointer",
        marginRight: "20px",
      }}
      onClick={() => setDisplay(0)}
    />
  ) : (
    <CreditCardFilled
      style={{
        fontSize: "20px",
        marginRight: "20px",
      }}
    />
  );

  const Menu2 = display ? (
    <DownSquareFilled style={{ fontSize: "20px" }} />
  ) : (
    <DownSquareOutlined
      style={{ fontSize: "20px", cursor: "pointer" }}
      onClick={() => setDisplay(1)}
    />
  );

  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "20px",
        width: "95%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span style={{ marginRight: "5%" }}>คลิกที่เอกสารที่ต้องการสร้าง</span>
      <span>
        {Menu1}
        {Menu2}
      </span>
    </div>
  );
};

export default SelectMenu;
