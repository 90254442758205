import { Modal, ModalProps, notification } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { postDocument } from "../utils/api/document";

//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface ConfirmPostModalProps {
  documentID: string;
  setIsModalVisible: (e: boolean) => void;
}
type Props = ModalProps & ConfirmPostModalProps;

//-------------------------------------------------------------------------//
// summary : component render section
//-------------------------------------------------------------------------//
const ConfirmPostModal: React.FC<Props> = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const closeModal = () => {
    props.setIsModalVisible(false);
  };

  const okHandler = async () => {
    //Allocate Document
    setLoading(true);
    postDocument(`CR${props.documentID}`)
      .then((response) => {
        const { uuid } = response;
        notification["success"]({
          message: "สำเร็จ",
          description:
            "คุณได้สร้างเอกสารเรียบร้อยแล้ว สามารถแก้ไขเอกสารได้ที่ กล่องเอกสารที่ฉันสร้าง (Documents Draft)",
          btn: (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/document/draft")}
            >
              ดูเอกสารทั้งหมด
            </span>
          ),
        });
        history.push(`/document/edit?id=${uuid}`);
      })
      .catch((e) => {
        notification["error"]({
          message: "ผิดพลาด",
          description: "ไม่สามารถสร้างเอกสารได้ โปรดติดต่อพนักงาน",
        });
      })
      .finally(() => {
        setLoading(false);
        closeModal();
      });
  };

  return (
    <Modal
      {...props}
      onOk={okHandler}
      onCancel={() => closeModal()}
      confirmLoading={loading}
    >
      {"ท่านยืนยันในการสร้างเอกสาร จท." + props.documentID + " หรือไม่"}
    </Modal>
  );
};

export default ConfirmPostModal;
