import { Route, Switch } from "react-router-dom";
import { useInfoContext } from "../Providers/UserProvider";
import DocumentPatch from "./document/DocumentPatch";
import DocumentOptions from "./document/DocumentOptions";
import DocumentHistory from "./document/DocumentHistory";
import Profile from "./Profile";
import NotFound404 from "./NotFound404";
import StaffManage from "./staff/StaffManage";
import StaffApprove from "./staff/StaffApprove";
import DocumentDraftList from "./document/DocumentDraftList";
import StaffHistory from "./staff/StaffHistory";

//-------------------------------------------------------------------------//
// summary : main router controller every router
//-------------------------------------------------------------------------//
function MainRouter() {
  const { userData } = useInfoContext();
  const { isStaff } = userData;

  return (
    <Switch>
      <Route exact path="/document/edit" component={DocumentPatch} />
      <Route exact path="/document/history" component={DocumentHistory} />
      <Route exact path="/document/draft" component={DocumentDraftList} />
      <Route exact path="/user/profile" component={Profile} />
      <Route
        exact
        path="/staff"
        component={isStaff ? StaffManage : NotFound404}
      />

      <Route
        path="/staff/document"
        component={isStaff ? StaffApprove : NotFound404}
      />
      <Route
        path="/staff/history"
        component={isStaff ? StaffHistory : NotFound404}
      />
      <Route exact path="/" component={DocumentOptions} />
      <Route component={NotFound404} />
    </Switch>
  );
}

export default MainRouter;
