import { notification, Table } from "antd";
import React from "react";
import { myDocumentHeader } from "../../utils/tableHeader";
import { avaliableDoc } from "../../Forms/config";
//-------------------------------------------------------------------------//
// summary :  component types section
//-------------------------------------------------------------------------//
interface Props {
  openPostModal: (code: string) => void;
  items: any[];
}

//-------------------------------------------------------------------------//
// summary : component function section
//-------------------------------------------------------------------------//
const TableList: React.FC<Props> = ({ openPostModal, items }) => {
  return (
    <Table
      onRow={(record) => {
        return {
          onClick: () => {
            if (avaliableDoc.some((e) => e === record.code)) {
              openPostModal(record.code);
            } else {
              notification["error"]({
                message: "ยังไม่พร้อมใช้งาน",
                description:
                  "เอกสารนี้ยังไม่เปิดให้ใช้งาน กรุณาลองอีกครั้งภายหลัง",
              });
            }
          }, // click row
        };
      }}
      columns={myDocumentHeader as any[]}
      dataSource={items}
    />
  );
};

export default TableList;
