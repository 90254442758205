import { UserInfo } from "../../../Providers/UserProvider";
import { dateFormat, JSON41, normalText12 } from "../../utils";

export default function step6({
	userData,
	values,
}: {
	userData: UserInfo;
	values: any;
}): JSON41 {
	return {
		"6_ความเห็นผู้อำนวยการ": normalText12(
			values.input["ความเห็นผู้อำนวยการ"]
		),
		"6_นามผู้อำนวยการ": normalText12(
			userData.name + " " + userData.surname
		),
		"6_วันลงนามผู้อำนวยการ": dateFormat(new Date()),
	};
}
